import { useCallback } from "react";
import { clearAllCookies, getCookie } from "../utils/api";
import { useLoginPopup } from "../context/LoginPopupContext";
import Cookies from "js-cookie";
import envConfig from "../config";

export const checkAndUpdateApiKeyExpiration = () => {
  const apikeyexpirationdate = getCookie("apiKeyExpirationUTCDate");

  const expirationDate = new Date(apikeyexpirationdate + "Z");
  const now = new Date();

  const timeDifference = expirationDate.getTime() - now.getTime();
  // const fifteenMinutes = 15 * 60 * 1000;
  const prolongByShort = envConfig.loginUrl.includes(".app")
    ? 2 * 60 * 1000
    : 15 * 60 * 1000;

  const expiryPeriodLong = 3 * 24 * 60 * 60 * 1000; // 3 days

  let newExpirationDate;
  if (timeDifference <= prolongByShort) {
    newExpirationDate = new Date(now.getTime() + prolongByShort);
  } else {
    newExpirationDate = new Date(now.getTime() + expiryPeriodLong);
  }

  Cookies.set(
    "apiKeyExpirationUTCDate",
    newExpirationDate.toISOString().split(".")[0],
    {
      path: "/",
    },
  );
};

const useApi = () => {
  const { handleOpen } = useLoginPopup();

  const apiCall = useCallback(
    async ({
      url,
      method = "GET",
      ip = "31.42.0.186",
      headers = {},
      body = null,
      onSuccess = () => {},
      onError = () => {},
    }) => {
      try {
        const defaultHeaders =
          Object.keys(headers).length > 0
            ? headers
            : {
                "Content-Type": "application/json",
                Accept: "application/json",
                "olivs-api-real-ip": ip,
                "olivs-api-computer-name": "BTM-WS2019",
                "session-id": getCookie("sessionId"),
                "browser-agent": window.navigator.userAgent,
                "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
                "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
              };
        const response = await fetch(url, {
          method,
          headers: { ...defaultHeaders },
          body: body ? JSON.stringify(body) : null,
        });

        if (response.status === 403 || response.status === 401) {
          // clearAllCookies();
          onError("Your session has expired, please log back in.");
          if (process.env.NODE_ENV === "development") {
            return;
          } else {
            setTimeout(async () => {
              handleOpen();
              // window.location = `https://${envConfig.loginUrl}/`;
            }, 1000);
            return;
          }
        }

        if (
          url.includes(envConfig.apiDev1) ||
          url.includes(envConfig.apiDev2)
        ) {
          checkAndUpdateApiKeyExpiration();
        }

        const data = await response.json();

        if (
          (response.ok && data.SuccessYN === "Y") ||
          data.successYN === "Y" ||
          data.SmsSentYN === "Y" ||
          data.Secret2FAKey ||
          data.User2FADisabledYN ||
          data.User2FAEnabledYN
        ) {
          onSuccess(data);
        } else {
          const errorMessage =
            typeof data === "string"
              ? data
              : data.ErrorMessage || data.errorMessage || "An error occurred";
          console.log(errorMessage);
          onError(errorMessage, data);
        }
      } catch (error) {
        const errorMessage = error.message || "An error occurred";

        if (
          errorMessage.includes("Failed to fetch") ||
          errorMessage.includes("NetworkError")
        ) {
          clearAllCookies();
          if (process.env.NODE_ENV !== "development") {
            window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}`;
          }
        } else {
          console.log(errorMessage);
          onError(errorMessage);
        }
      }
    },
    [handleOpen],
  );

  return { apiCall };
};

export default useApi;
