import envConfig from "../../../config";
import { getHeaders } from "../../../utils/api";
import useApiCall from "../../../hooks/ApiCall";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { EXAMPLE_GET_CONTACTS_RESPONSE } from "../../../data/example-get-contacts-response";

const contactsUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/contacts/get-selection-list?activeYN=Y&typeCS=C&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: Move it somewhere else - probably types - remove duplicates
type ResponseBoolean = "Y" | "N";

// TODO: Move it somewhere else - probably types
export interface Client {
  cntId: number;
  cntCode: string;
  cntName: string;
  cntTaxCode: string;
  cntTaxCodeFull: string;
  cntEmail: string;
  cntPhoneNo: string;
}

export interface ContactsResponse {
  ListOfContacts: Client[];
  errorMessage: ResponseBoolean;
  successYN: ResponseBoolean;
}

export const useGetContacts = () => {
  const headers = getHeaders();
  const { handleOpen } = useLoginPopup();

  // TODO: IMPORTANT: Remove this line in final version
  if (process.env.NODE_ENV === "development") {
    return EXAMPLE_GET_CONTACTS_RESPONSE;
  }

  // TODO: IMPORTANT: Remove this line in final version
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useApiCall({
    url: contactsUrl,
    method: "GET",
    headers: headers,
    handleOpen,
  }) as { data: ContactsResponse | null; error: any; loading: boolean };
};
