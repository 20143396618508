import { useMutation } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { MutationConfig } from "../../../../lib/react-query";
import { getHeaders } from "../../../../utils/api";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

export const updateTaxCode = async (taxCodeBody: any, handleOpen = () => {}) => {
  // if (process.env.NODE_ENV === "development") {
  //   return {};
  // }
  const controllerName = "tax-codes";
  const actionName = "update";
  const api = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  console.log(taxCodeBody);

  const headers = getHeaders();
  const response = await fetch(api, {
    method: "POST",
    // @ts-ignore
    headers: {
      ...headers,
    },
    body: JSON.stringify(taxCodeBody),
  });

  if (response.status === 403 || response.status === 401) {
    setTimeout(async () => {
      handleOpen();
      // window.location =
      //   `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
    }, 1000);
    throw new Error("Your session has expired, please log back in.");
  }

  if (api.includes(envConfig.apiDev1) || api.includes(envConfig.apiDev2)) {
    checkAndUpdateApiKeyExpiration();
  }

  const result = await response.json();

  if (response.ok && (result?.SuccessYN === "Y" || result?.successYN === "Y")) {
    return result;
  } else {
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

// type UseUpdateTaxCodeType = {
//   mutationConfig?: MutationConfig<typeof updateTaxCode>;
//   handleOpen: any;
// };

export const useUpdateTaxCode = () => {
  const { handleOpen } = useLoginPopup();

  return useMutation({
    mutationFn: (body) => updateTaxCode(body, handleOpen),
  });
};
