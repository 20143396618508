import { useMutation } from "@tanstack/react-query";

import { getHeaders } from "../../../../utils/api";
import { downloadPDF } from "../../../../utils/reusable";
import { MutationConfig } from "../../../../lib/react-query";
import envConfig from "../../../../config";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

// TODO: This should be an Axios API call instead of fetch
// in the Axios Instance where we can set up common headers
// middleware for expired api keys and sessions etc.
export const downloadCustomReportPDF = async (
  body,
  handleOpen
): Promise<any> => {
  const controllerName = "reports";
  const actionName = "get-pdf-report-pack-rs1";
  const api = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  const headers = getHeaders();

  const jsonString = `{${body}}`;
  const formattedString = jsonString.replace(/(\w+):/g, '"$1":');

  const response = await fetch(api, {
    method: "POST",
    // @ts-ignore
    headers: { "Content-Type": "application/json", ...headers },
    body: formattedString,
  });

  if (response.status === 403 || response.status === 401) {
    setTimeout(async () => {
      handleOpen()
      // window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
    }, 1000);
    throw new Error("Your session has expired, please log back in.");
  }

  if (api.includes(envConfig.apiDev1) || api.includes(envConfig.apiDev2)) {
    checkAndUpdateApiKeyExpiration();
  }

  const result = await response.json();

  if (response.ok && result?.ReportBase64 && result?.SuccessYN === "Y") {
    const isPDFDownlaoded = await downloadPDF(
      result?.ReportBase64,
      result?.FileName,
    );
    return isPDFDownlaoded;
  } else {
    console.log(
      "Error: ",
      result?.ErrorMessage || result?.errorMessage,
      ". Response: ",
      result,
    );
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

type UseDownloadPDFCustomReport = {
  mutationConfig?: MutationConfig<typeof downloadCustomReportPDF>;
};

export const useDownloadPDFCustomReport = ({
  mutationConfig,
}: UseDownloadPDFCustomReport = {}) => {
  const { ...restConfig } = mutationConfig || {};
  const { handleOpen } = useLoginPopup();

  return useMutation({
    ...restConfig,
    mutationFn: (body) =>downloadCustomReportPDF(body, handleOpen),
  });
};
