import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import GeneralJournalHeader from "./GeneralJournalHeader";
import GeneralJournalGrid from "./GeneralJournalGrid";
import { useParams } from "react-router-dom";
import { getHeaders } from "../../utils/api";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import envConfig from "../../config";
import PageTitle from "../PageTitle/PageTitle";
import useApi from "../../hooks/useApi";
import { enqueueSnackbar } from "notistack";

const GeneralJournalPage = () => {
  const { dbId, lang, journalId } = useParams();
  const headers = getHeaders();
  const { apiCall } = useApi();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageKey, setPageKey] = useState(1);
  const [formState, setFormState] = useState({
    jnlDate: null,
    jnlContact_cntID: null,
    jnlMemo: null,
    showPrivatePortion: false,
    showVaryManualTax: false,
  });

  const reloadPage = () => {
    setData(null);
    setPageKey((prev) => prev + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/general-journal/get-journal-data?journalId=${journalId || 0}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        ip: "127.0.0.2",
        headers,
        onSuccess: (data) => {
          // Check conditions in JournalDetails
          const showVaryManualTax = data?.JournalDetails?.some(
            (detail) => detail.jnsVaryTaxYN === "Y",
          );
          const showPrivatePortion = data?.JournalDetails?.some(
            (detail) => detail.jnsPrivAmt > 0,
          );

          setData(data);
          setFormState({
            jnlDate: dayjs(data?.JournalHeader?.jnlDate),
            jnlContact_cntID: data?.JournalHeader?.jnlContact_cntID || null,
            jnlMemo: data?.JournalHeader?.jnlMemo || "",
            showPrivatePortion: showPrivatePortion,
            showVaryManualTax: showVaryManualTax,
          });
          setLoading(false);
        },
        onError: (errorMessage) => {
          console.log(errorMessage);
          enqueueSnackbar(errorMessage, { variant: "error" });
          setLoading(false);
        },
      });
    };

    fetchData();
  }, [journalId, pageKey]);

  return (
    <div key={pageKey}>
      <BackdropLoading open={loading} />
      <PageTitle title="General Journal" />
      <GeneralJournalHeader
        formState={formState}
        setFormState={setFormState}
        listOfContacts={data?.ListOfContacts}
      />
      <GeneralJournalGrid
        loadingData={loading}
        formState={formState}
        headerData={data?.JournalHeader || {}}
        initialData={data?.JournalDetails || []}
        listOfAccounts={data?.ListOfAccounts}
        listOfTaxCodes={data?.ListOfTaxCodes}
        listOfSites={data?.ListOfSites}
        showPrivatePortion={formState?.showPrivatePortion}
        setShowPrivatePortion={(boolean) =>
          setFormState({ ...formState, showPrivatePortion: !!boolean })
        }
        showVaryManualTax={formState?.showVaryManualTax}
        reloadPage={reloadPage}
      />
    </div>
  );
};

export default GeneralJournalPage;
