import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ChartOfAccountsTable from "./components/ChartOfAccountsTable";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import EditAccountPopup from "./components/EditAccountPopup";

const ChartOfAccountsPage = () => {
  const { dbId, lang } = useParams();
  const prefix = `/${dbId}/${lang}/`;
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [cashFlows, setCashFlows] = useState([]);
  const [accountDataSets, setAccountDataSets] = useState([]);
  const [grouping, setGrouping] = useState("Group by type"); // Default grouping option
  const { apiCall } = useApi();
  const [loading, setLoading] = useState(true);
  const [openNewAccountPopup, setOpenNewAccountPopup] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const datasetUrl = `https://${envConfig.apiDev1}/api/v3/en-au/accounts/datasets?BaseHostURL=s1.olivs.app`;
      const accountsUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/get-list-of-accounts?BaseHostURL=s1.olivs.com`;
      const taxCodesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/tax-codes/get-selection-list?BaseHostURL=s1.olivs.com`;

      await apiCall({
        url: datasetUrl,
        method: "GET",
        onSuccess: (data) => {
          setAccountDataSets(data);
          setAccountTypes(data.ListOfAccountTypes || []);
          setHeaders(data.ListOfAccountHeaders || []);
          setCashFlows(data.ListOfCashFlow || []);
        },
        onError: (error) => {
          console.error("Failed to fetch datasets:", error);
        },
      });

      await apiCall({
        url: accountsUrl,
        method: "GET",
        onSuccess: (data) => {
          console.log(data);
          setAccounts(data?.listOfAccounts || []);
        },
        onError: (error) => {
          console.error("Failed to fetch accounts:", error);
        },
      });

      await apiCall({
        url: taxCodesUrl,
        method: "GET",
        onSuccess: (data) => {
          setTaxCodes(data.listOfTaxCodes || []);
        },
        onError: (error) => {
          console.error("Failed to fetch tax codes:", error);
        },
      });
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [apiCall]);

  const handleGroupingChange = (event) => {
    setGrouping(event.target.value);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <BackdropLoading loading={loading} />
      {openNewAccountPopup && (
        <EditAccountPopup
          refetchData={fetchData}
          accountDataSets={accountDataSets}
          setOpenAddAccountPopup={(open) => {
            setOpenNewAccountPopup(open);
          }}
          taxCodes={taxCodes}
          accounts={accounts}
        />
      )}
      <Typography variant="h4" gutterBottom>
        Chart of Accounts
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <RadioGroup row value={grouping} onChange={handleGroupingChange}>
          <FormControlLabel
            value="No grouping"
            control={<Radio />}
            label="No grouping"
          />
          <FormControlLabel
            value="Group by type"
            control={<Radio />}
            label="Group by type"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setOpenNewAccountPopup(true)}
          variant="contained"
          color="primary"
        >
          New Account
        </Button>
        <Button
          onClick={() => navigate(`${prefix}books/tax-codes`)}
          variant="outlined"
        >
          GST Settings
        </Button>
      </Box>

      <ChartOfAccountsTable
        accounts={accounts}
        taxCodes={taxCodes}
        headers={headers}
        accountTypes={accountTypes}
        cashFlows={cashFlows}
        grouping={grouping}
        accountDataSets={accountDataSets}
        refetchData={fetchData}
      />
    </Box>
  );
};

export default ChartOfAccountsPage;
