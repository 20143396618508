import PageTitle from "../../components/PageTitle/PageTitle";
import { ReactComponent as EmptyFolderIcon } from "../../assets/dashboard-empty-file.svg";
import {
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import OurDictionary from "../../components/OurDictionary/OurDictionary";
import { useNavigate, useParams } from "react-router-dom";
import useApiCall from "../../hooks/ApiCall";
import { getCookie, getHeaders, getLoginSecured } from "../../utils/api";
import { ReactComponent as StarIcon } from "../../assets/star-icon.svg";
import { ReactComponent as StarFilled } from "../../assets/star_filled.svg";
import { ReactComponent as StarDelete } from "../../assets/star_delete.svg";
import { useTheme } from "../../context/ThemeContext";
import Cookies from "js-cookie";
import { useLoginPopup } from "../../context/LoginPopupContext";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { enqueueSnackbar } from "notistack";
import { createSessionToLogin } from "../../utils/reusable";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";

const EmptyDashboard = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const [ip, setIp] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const responseIp = await fetch("https://api.ipify.org?format=json");
      const dataIp = await responseIp.json();
      setIp(dataIp?.ip);
    };
    if (!ip) fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        pt: 5,
        pb: 22.25,
        textAlign: "center",
        px: 5,
      }}
    >
      <EmptyFolderIcon />
      <Typography
        sx={{
          color: isDarkMode ? "#FFF" : "#252525",
          mb: 4,
          mt: 8,
          fontWeight: 300,
          letterSpacing: "-0.176px",
        }}
      >
        If you are awaiting an invitation to the subscriber’s space, please
        contact the owners and make sure invitation was sent to john@mail.com.{" "}
      </Typography>
      <Typography
        sx={{
          color: isDarkMode ? "#FFF" : "#252525",
          fontWeight: 300,
          letterSpacing: "-0.176px",
        }}
      >
        If you are starting your own Olivs experience, let’s create your
        business account.
      </Typography>
      <Typography
        sx={{
          color: isDarkMode ? "#FFF" : "#252525",
          fontWeight: 300,
          letterSpacing: "-0.176px",
        }}
      >
        You can enjoy 3 months using Olivs completely for free! No payment
        details needed.
      </Typography>
      <Button
        onClick={async () => {
          setLoading(true);
          await createSessionToLogin(
            `https://${envConfig.loginUrl}/create-new-subscriber`,
            ip,
          );
          setLoading(true);
        }}
        disabled={loading}
        sx={{ mt: 5 }}
        disableRipple
        type="submit"
        variant="contained"
        color="primary"
      >
        Start My Own Olivs Experience
      </Button>
    </Box>
  );
};

const InvitationsDashboard = () => {
  const { isDarkMode } = useTheme();
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <OurDictionary isOpen={isOpen} setIsOpen={setIsOpen} />
      <Box
        sx={{
          p: 4,
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 400,
          }}
        >
          Invitations
        </Typography>
        <DashboardInvitation
          details={{
            account_type: "subscriber",
            company_name: "Premium Accounting Pty Ltd",
            sent_by: "David Smith",
          }}
        />
        <Box
          sx={{
            borderBottom: "1px solid var(--divider, #EDEDED)",
          }}
        />
        <DashboardInvitation
          details={{
            account_type: "business",
            company_name: "Company Two",
            sent_by: "John Trolley",
          }}
        />
      </Box>
      <Box
        sx={{
          pt: "93px",
          pb: "121px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          textAlign: "center",
          marginTop: "47px",
          marginBottom: "61px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "150%",
            letterSpacing: "-0.176px",
            maxWidth: 887,
            margin: "0 auto",
            px: secondBreakpoint ? 2 : 0,
          }}
        >
          If you want to become a subscriber and have your own space for adding
          new businesses, you can create a new subscriber below. You can have as
          many subscribers as you want.
        </Typography>
        <Typography
          className="link"
          onClick={() => {
            setIsOpen(true);
          }}
          sx={{
            color: isDarkMode ? "#AAD269" : "#849F23",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "150%",
            letterSpacing: "-0.176px",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Read More About Subscribers
        </Typography>
        <Button
          onClick={() => {
            navigate("/create-new-subscriber");
          }}
          sx={{ mt: "20px", mx: "auto" }}
          disableRipple
          variant="contained"
          color="primary"
        >
          Create New Subscriber
        </Button>
      </Box>
    </Box>
  );
};

const DashboardInvitation = ({ details }) => {
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");

  return (
    <Box sx={{ py: 2 }}>
      <Typography
        sx={{
          color: "#6D7B96",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "266%",
          letterSpacing: "1px",
          textTransform: "uppercase",
          mt: 0,
          mb: 2,
        }}
      >
        {details.account_type === "subscriber"
          ? "SUBSCRIBER ACCOUNT INVITATION"
          : "BUSINESS ACCOUNT INVITATION"}
      </Typography>
      <Typography
        sx={{
          mt: 0,
          mb: 1,
          color: "rgba(0, 0, 0, 0.60)",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "166%",
          letterSpacing: "0.4px",
        }}
      >
        {details.account_type === "subscriber"
          ? "You’ve been invited to join Subscriber Account. This membership will allow you to access and manage businesses linked to this account."
          : "You’ve been invited to join Business Account. You’ll get access to your business account shared with Premium Accounting Pty Ltd Subscriber Account."}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: secondBreakpoint ? "column" : "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: secondBreakpoint ? "column" : "row",
          }}
        >
          <Typography
            sx={{
              color: "#252525",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              my: 0,
              mr: 2,
            }}
          >
            {details.company_name}
          </Typography>
          <Typography
            sx={{
              color: "#818595",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.15px",
            }}
          >{`Invitation sent by ${details.sent_by}`}</Typography>
        </Box>
        <Box
          sx={{
            textAlign: secondBreakpoint ? "right" : "unset",
            mt: secondBreakpoint ? 1 : 0,
          }}
        >
          <Button
            sx={{
              background: "transparent",
              height: "36px",
              mr: "9px",
            }}
            disableRipple
            variant="secondary"
          >
            Decline
          </Button>
          <Button disableRipple variant="contained" color="primary">
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const BusinessFilesDashboard = ({ files, fullwidth, handleDbSelect }) => {
  const firstBreakpoint = useMediaQuery("(max-width: 1100px)");
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("mostRecent");

  const { isDarkMode } = useTheme();

  const filteredAndSortedBusinesses = () => {
    let filteredBusinesses = files?.filter((business) =>
      business.xxbBusinessName.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    if (sortOption === "mostRecent") {
      filteredBusinesses.sort(
        (a, b) =>
          new Date(b.ssnLastConnnectedDate) - new Date(a.ssnLastConnnectedDate),
      );
    } else if (sortOption === "oldest") {
      filteredBusinesses.sort(
        (a, b) =>
          new Date(a.ssnLastConnnectedDate) - new Date(b.ssnLastConnnectedDate),
      );
    }

    return filteredBusinesses;
  };

  return (
    <Container
      sx={{
        marginTop: 5,
        p: 1,
        pb: 3,
        position: "relative",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "24px",
          width: secondBreakpoint ? "206px" : "unset",
          right: fullwidth
            ? "24px"
            : secondBreakpoint
              ? 0
              : firstBreakpoint
                ? "unset"
                : "32px",
          textAlign: secondBreakpoint ? "right" : "unset",
          width: secondBreakpoint ? "206px" : "unset",
        }}
      >
        <Button
          sx={{
            color: isDarkMode ? "#AAD269" : "#849F23",
            background: "rgba(132, 159, 35, 0.08)",
            height: "36px",
            mr: secondBreakpoint ? 0 : 2,
            mb: secondBreakpoint ? 1 : 0,
            "&:hover": {
              background: "rgba(132, 159, 35, 0.12)",
            },
          }}
          disableRipple
          variant="secondary"
          onClick={() => {}}
        >
          Create Demo Business
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: secondBreakpoint ? "190px" : "unset",
            justifyContent: secondBreakpoint ? "center" : "unset",
          }}
          onClick={() => {
            navigate("create");
          }}
        >
          {`+   Add New Business`}
        </Button>
      </Box>
      <Typography
        sx={{
          fontSize: "24px",
          mt: 3,
          mb: 3,
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "-0.264px",
          mt: secondBreakpoint ? "100px" : firstBreakpoint ? "64px" : "24px",
        }}
      >
        Your Businesses
      </Typography>
      <Box sx={{ display: "flex" }}>
        <TextField
          sx={{
            label: { fontSize: 16, marginTop: 0 },
            maxWidth: "240px",
            mr: 2,
            height: "36px",
          }}
          size="small"
          fullWidth
          label="Search"
          placeholder="Search..."
          variant="outlined"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel sx={{ fontSize: "16px" }}>Sort By</InputLabel>
          <Select
            size="small"
            sx={{ fontSize: "16px", maxWidth: "180px" }}
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            label="Sort By"
          >
            <MenuItem value="">A-Z</MenuItem>
            <MenuItem value="mostRecent">Most Recent</MenuItem>
            <MenuItem value="oldest">Oldest</MenuItem>
            {/* Add more sorting options as needed */}
          </Select>
        </FormControl>
      </Box>

      <Grid
        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
        container
        spacing="12px"
      >
        {filteredAndSortedBusinesses().map((business, index) => (
          <Grid
            item
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDbSelect(business.xxbID);
            }}
          >
            {business.xxlFavouriteFileYN === "Y" ? (
              <StarFilled />
            ) : (
              <StarIcon />
            )}
            <Typography
              variant="h6"
              sx={{
                mx: 2,
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {business.xxbBusinessName}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#818595",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >{`Last Activity: ${
              business.ssnLastConnnectedDate
                ? new Date(business.ssnLastConnnectedDate) <
                  new Date("2023-01-01")
                  ? "not yet accessed"
                  : new Date(
                      business.ssnLastConnnectedDate,
                    ).toLocaleDateString()
                : "not yet accessed"
            }`}</Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const FavoritesDashboard = ({ favorites = [], fullwidth, handleDbSelect }) => {
  return (
    <Container
      sx={{
        marginTop: 5,
        p: 1,
        pb: 3,
        position: "relative",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        maxWidth: fullwidth ? "unset" : "416px",
        ml: fullwidth ? 0 : "16px",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          mt: 3,
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "-0.264px",
        }}
      >
        Favorites
      </Typography>
      <Grid
        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
        container
        spacing="12px"
      >
        {favorites.map((business, index) => (
          <Grid
            item
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDbSelect(business.xxbID);
            }}
          >
            <StarDelete />
            <Typography
              variant="h6"
              sx={{
                mx: 2,
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {business.xxbBusinessName}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const Dashboard = () => {
  const secondBreakpoint = useMediaQuery("(max-width: 875px)");
  const LoginSecuredHash = getLoginSecured();
  const GlobalUserID = getCookie("globalUserId");
  const navigate = useNavigate();
  const { dbId, lang } = useParams();
  const ip = useGetIP();
  const headers = getHeaders();
  const { apiCall } = useApi();

  const [businessFilesOfUserData, setBusinessFilesOfUserData] = useState(null);
  const [buisnessFilesOfUserError, setBusinessFilesOfUserError] =
    useState(null);
  const [businessFilesOfUserLoading, setBusinessFilesOfUserLoading] =
    useState(true);

  const [invitationsData, setInvitationsData] = useState(null);
  const [invitationsError, setInvitationsError] = useState(null);
  const [invitationsLoading, setInvitationsLoading] = useState(true);

  useEffect(() => {
    apiCall({
      url: `https://${envConfig.apiDev1}/api/v3/en-au/databases/get-user-databases?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      headers,
      ip: ip,
      body: {
        LoginSecuredHash,
        GlobalUserID,
      },
      onSuccess: (data) => {
        setBusinessFilesOfUserData(data);
        setBusinessFilesOfUserLoading(false);
      },
      onError: (error) => {
        setBusinessFilesOfUserError(error);
        setBusinessFilesOfUserLoading(false);
      },
    });

    apiCall({
      url: `https://${envConfig.apiDev1}/api/v3/en-au/invitations/get-user-data?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      headers,
      body: {
        LoginSecuredHash,
        GlobalUserID,
      },
      onSuccess: (data) => {
        setInvitationsData(data);
        setInvitationsLoading(false);
      },
      onError: (error) => {
        setInvitationsError(error);
        setInvitationsLoading(false);
      },
    });
  }, [apiCall, GlobalUserID, LoginSecuredHash, headers]);

  const invitations = invitationsData?.ListOfInvitations;
  const requestedAccess = invitationsData?.ListOfRequestedAccess;
  const firstName = getCookie("firstName");

  const favorites = businessFilesOfUserData?.ListOfBusinessFiles?.filter(
    (business) => business?.xxlFavouriteFileYN === "Y",
  );

  const handleDbSelect = (dbId) => {
    Cookies.set("databaseId", dbId);
    navigate(`/${dbId}/en-au/mobile/dashboard`);
  };

  if (invitationsLoading || businessFilesOfUserLoading) {
    return (
      <BackdropLoading
        open={invitationsLoading || businessFilesOfUserLoading}
      />
    );
  }

  return (
    <>
      <PageTitle
        title={`Welcome to Olivs${
          firstName && firstName !== "undefined" ? `, ${firstName}!` : "!"
        }`}
      />
      {businessFilesOfUserData?.ListOfBusinessFiles?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: secondBreakpoint ? "column" : "row-reverse",
          }}
        >
          {favorites?.length > 0 && (
            <FavoritesDashboard
              fullwidth={secondBreakpoint}
              favorites={favorites}
              handleDbSelect={handleDbSelect}
            />
          )}
          <BusinessFilesDashboard
            fullwidth={secondBreakpoint}
            files={businessFilesOfUserData?.ListOfBusinessFiles}
            handleDbSelect={handleDbSelect}
          />
        </Box>
      )}
      {!invitations?.length > 0 &&
        !requestedAccess?.length > 0 &&
        !businessFilesOfUserData?.ListOfBusinessFiles?.length > 0 && (
          <EmptyDashboard />
        )}
      {invitations?.length > 0 && <InvitationsDashboard />}
    </>
  );
};

export default Dashboard;
