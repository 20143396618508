import React, { useEffect, useState, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import DataGrid, {
  Column,
  Editing,
  Summary,
  TotalItem,
  Selection,
  Pager,
  Paging,
  Scrolling,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DOMPurify from "dompurify";
import Popup from "devextreme-react/popup";
import Button from "devextreme-react/button";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { Box, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContextMenu from "devextreme-react/context-menu";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import FilesUploadedPopup from "./FilesUploadedPopup";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import { useGetIP } from "../../hooks/get-ip";
import useApi from "../../hooks/useApi";
import ReportDatesContext from "../../context/ReportDatesContext";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../utils/api";
import AddAccountPopup from "./AddAccountPopup";
import JournalSavedPopup from "../BankJournal/JournalSavedPopup";

const GeneralJournalGrid = ({
  loadingData,
  formState,
  headerData,
  initialData,
  listOfAccounts,
  listOfSites,
  listOfTaxCodes,
  showPrivatePortion,
  setShowPrivatePortion,
  showVaryManualTax,
  reloadPage,
}) => {
  const largeScreen = useMediaQuery("(min-width: 1160px)");
  const { journalId } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const ip = useGetIP();
  const { enqueueSnackbar } = useSnackbar();
  const [gridData, setGridData] = useState(initialData);
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [isImportPopupVisible, setIsImportPopupVisible] = useState(false);
  const fileInputRef = useRef(null);
  const dataGridRef = useRef(null);
  const [openFilesPopup, setOpenFilesPopup] = useState(false);
  const [openAddAccountPopup, setOpenAddAccountPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { interfacePreference } = useContext(ReportDatesContext);

  const [accounts, setAccounts] = useState([]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [savedJournalId, setSavedJournalId] = useState(null);

  const setValue = (value, field, key) => {
    setGridData((prevData) =>
      prevData.map((item) => {
        if (item.jnsID === key) {
          const updatedItem = { ...item, [field]: value };
          if (field === "jnsAccount_accNo") {
            const account = accounts?.find((acc) => acc.accNo === value);
            updatedItem.jnsTaxCode_txcID = account?.defaultTaxCode || "";
            const totalDebit = prevData.reduce(
              (sum, item) => sum + (item.jnsDr || 0),
              0,
            );
            const totalCredit = prevData.reduce(
              (sum, item) => sum + (item.jnsCr || 0),
              0,
            );
            const outAmount =
              Math.round((totalDebit - totalCredit) * 100) / 100;
            if (!updatedItem.jnsDr && !updatedItem.jnsCr) {
              if (outAmount > 0) {
                updatedItem.jnsCr = outAmount;
                updatedItem.jnsDr = null;
              } else {
                updatedItem.jnsDr = Math.abs(outAmount);
                updatedItem.jnsCr = null;
              }
            }
          }
          if (
            field === "jnsDr" ||
            field === "jnsCr" ||
            field === "jnsTaxCode_txcID" ||
            field === "txcCode"
          ) {
            updatedItem.jnsTaxAmt = calculateTaxAmount(updatedItem);
          }
          if (field === "jnsPrivPc" || field === "jnsDr" || field === "jnsCr") {
            updatedItem.jnsPrivAmt = calculatePrivateAmount(updatedItem);
            updatedItem.jnsTaxAmt = calculateTaxAmount(updatedItem);
          }
          if (field === "jnsDr" && value !== null) {
            updatedItem.jnsCr = null;
          }
          if (field === "jnsCr" && value !== null) {
            updatedItem.jnsDr = null;
          }
          if (field === "jnsVaryTaxYN") {
            updatedItem.jnsVaryTaxYN = value ? "Y" : "N";
          }
          if (field === "jnsReviewYN" && value !== null) {
            updatedItem.jnsReviewYN = value ? "Y" : "N";
          }
          return updatedItem;
        }
        return item;
      }),
    );
  };

  const calculateTaxAmount = (item) => {
    const taxCode = listOfTaxCodes?.find(
      (tc) => tc.txcID === item.jnsTaxCode_txcID,
    );
    const rate = taxCode ? taxCode.txcRate : 0;
    const baseAmount = item.jnsDr || item.jnsCr || 0;
    const taxAmount =
      baseAmount -
      (item.jnsPrivAmt || 0) -
      (baseAmount - (item.jnsPrivAmt || 0)) / (1 + rate);
    return Math.round(taxAmount * 100) / 100; // Round to two decimal places
  };

  const calculatePrivateAmount = (item) => {
    const baseAmount = item.jnsDr || item.jnsCr || 0;
    if ((baseAmount * (item.jnsPrivPc || 0)) / 100) {
      setShowPrivatePortion(true); // Keep this line as you requested
    }
    const privateAmount = (baseAmount * (item.jnsPrivPc || 0)) / 100;
    return Math.round(privateAmount * 100) / 100; // Round to two decimal places
  };

  const applyGrossAmount = (e) => {
    return true;
  };

  const applyTaxAmount = (e) => {
    return true;
  };

  useEffect(() => {
    if (loadingData) return;
    if (gridData?.length) return;
    const sortedInitialData = initialData.sort(
      (a, b) => a.jnsOrder - b.jnsOrder,
    );

    let updatedData = [...sortedInitialData];

    if (updatedData.length < 5) {
      const additionalRows = 5 - updatedData.length;
      const emptyRows = Array.from({ length: additionalRows }, (_, i) => ({
        jnsID: `empty-${updatedData.length + i + 1}`,
        jnsAccount_accNo: "",
        jnsDr: null,
        jnsCr: null,
        jnsTaxCode_txcID: "",
        jnsPrivPc: null,
        jnsReviewYN: "N",
        jnsVaryTaxYN: "N",
        jnsTaxAmt: null,
        jnsPrivAmt: null,
        jnsNetBusAmt: null,
        jnsSite_dpsID: "",
        jnsOrder: updatedData.length + i + 1,
      }));
      updatedData = [...updatedData, ...emptyRows];
    }

    setGridData(updatedData);
  }, [initialData, loadingData]);

  const handleDelete = (rowId) => {
    setGridData((prevData) => prevData.filter((item) => item.jnsID !== rowId));
  };

  const handleAddRows = () => {
    const maxId = Math.max(
      ...gridData.map((item) => parseInt(item.jnsID, 10) || 0),
      0,
    );
    setGridData((prevData) => [
      ...prevData,
      ...Array(5)
        .fill({})
        .map((_, index) => ({
          jnsID: maxId + index + 1,
          jnsAccount_accNo: "",
          jnsDr: null,
          jnsCr: null,
          jnsTaxCode_txcID: "",
          jnsPrivPc: null,
          jnsReviewYN: "N",
          jnsVaryTaxYN: "N",
          jnsTaxAmt: null,
          jnsPrivAmt: null,
          jnsNetBusAmt: null,
          jnsSite_dpsID: "",
        })),
    ]);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "n") {
        event.preventDefault();
        handleAddRows();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const getTaxCodeDisplayValue = (rowData) => {
    const taxCode = listOfTaxCodes?.find(
      (taxCode) => taxCode.txcID === rowData.jnsTaxCode_txcID,
    );
    return taxCode ? taxCode.txcCode : "";
  };

  const renderAddButton = () => {
    return (
      <Button
        icon="add"
        text="Add (ALT + N)"
        onClick={handleAddRows}
        style={{
          fontSize: "11px",
          marginLeft: "2px",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      />
    );
  };

  const calculateCustomSummary = (options) => {
    if (options.name === "OutSummary") {
      if (options.summaryProcess === "start") {
        options.totalDebit = 0;
        options.totalCredit = 0;
      }
      if (options.summaryProcess === "calculate") {
        if (options.value) {
          options.totalDebit += options.value.jnsDr || 0;
          options.totalCredit += options.value.jnsCr || 0;
        }
      }
      if (options.summaryProcess === "finalize") {
        const difference = options.totalDebit - options.totalCredit;
        if (difference > 0) {
          options.totalValue = `CR ${difference.toFixed(2)}`;
        } else if (difference < 0) {
          options.totalValue = `DR ${Math.abs(difference).toFixed(2)}`;
        } else {
          options.totalValue = "0.00";
        }
      }
    }
  };

  const handleCellPrepared = (e) => {
    if (
      e.rowType === "totalFooter" &&
      e.column.dataField === "jnsAccount_accNo"
    ) {
      e.cellElement.innerHTML = "";
      const button = document.createElement("div");
      button.style.display = "inline-block";
      e.cellElement.appendChild(button);

      ReactDOM.render(renderAddButton(), button);
    }
  };

  const openImportPopup = () => {
    setIsImportPopupVisible(true);
  };

  const closeImportPopup = () => {
    setIsImportPopupVisible(false);
  };

  const handleImportFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target.result;
        parseCSV(csv);
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (csv) => {
    const rows = csv.split("\n").map((row) => row.split(","));
    const header = rows[0];
    const data = rows
      .slice(1)
      .map((row, index) => {
        const debit = parseFloat(row[header.indexOf("Debit")]) || 0;
        const credit = parseFloat(row[header.indexOf("Credit")]) || 0;
        if (debit !== 0 && credit !== 0) {
          enqueueSnackbar(
            `Row ${index + 1}: Debit and Credit amounts are present in one row.`,
            {
              variant: "error",
            },
          );
          return null;
        }

        const taxCode = row[header.indexOf("Tax")] || "";
        const siteCode = row[header.indexOf("Site")] || "";
        const taxCodeObj = listOfTaxCodes?.find((tc) => tc.txcCode === taxCode);
        const siteCodeObj = listOfSites?.find(
          (site) => site.dpsCode === siteCode,
        );

        const privPc = parseFloat(row[header.indexOf("Priv %")]) || null;
        const taxAmt =
          taxCodeObj && row[header.indexOf("Tax Var")] !== "Y"
            ? calculateTaxAmount({
                jnsDr: debit,
                jnsCr: credit,
                jnsTaxCode_txcID: taxCodeObj.txcID,
                jnsPrivAmt:
                  parseFloat(row[header.indexOf("Private Amount")]) || null,
              })
            : parseFloat(row[header.indexOf("Tax Amount")]) || 0;

        const privAmt =
          privPc !== null && privPc > 0
            ? calculatePrivateAmount({
                jnsDr: debit,
                jnsCr: credit,
                jnsPrivPc: privPc,
              })
            : null;

        return {
          jnsID: `imported-${index + 1}`, // Generate a unique ID for each row
          jnsAccount_accNo: row[header.indexOf("Account")] || "",
          jnsDr: debit,
          jnsCr: credit,
          jnsTaxCode_txcID: taxCodeObj ? taxCodeObj.txcID : "",
          jnsPrivPc: privPc,
          jnsReference: row[header.indexOf("Memo")],
          jnsReviewYN: row[header.indexOf("Review")] === "Y" ? "Y" : "N",
          jnsVaryTaxYN: row[header.indexOf("Tax Var")] === "Y" ? "Y" : "N",
          jnsTaxAmt: taxAmt,
          jnsPrivAmt: privAmt,
          jnsSite_dpsID: siteCodeObj ? siteCodeObj.dpsID : "",
        };
      })
      .filter((row) => row !== null);

    // Ensure the number of rows is a multiple of 5
    const rowsToAdd = 5 - (data.length % 5);
    if (rowsToAdd !== 5) {
      for (let i = 0; i < rowsToAdd; i++) {
        data.push({
          jnsID: `empty-${data.length + 1}`,
          jnsAccount_accNo: "",
          jnsDr: null,
          jnsCr: null,
          jnsTaxCode_txcID: "",
          jnsPrivPc: null,
          jnsReviewYN: "N",
          jnsVaryTaxYN: "N",
          jnsTaxAmt: null,
          jnsPrivAmt: null,
          jnsNetBusAmt: null,
          jnsSite_dpsID: "",
        });
      }
    }

    setGridData(data);
    fileInputRef.current.value = "";
    closeImportPopup();
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("JournalGeneral");
    worksheet.columns = [
      { header: "Account", key: "jnsAccount_accNo" },
      { header: "Debit", key: "jnsDr" },
      { header: "Credit", key: "jnsCr" },
      { header: "Tax", key: "jnsTaxCode_txcID" },
      { header: "Priv %", key: "jnsPrivPc" },
      { header: "Memo", key: "jnsReference" },
      { header: "Review", key: "jnsReviewYN" },
      { header: "Tax Var", key: "jnsVaryTaxYN" },
      { header: "Tax Amount", key: "jnsTaxAmt" },
      { header: "Site", key: "jnsSite_dpsID" },
    ];

    const transformedData = gridData
      .filter((row) => row.jnsAccount_accNo || row.jnsDr || row.jnsCr)
      .map((row) => {
        const taxCodeObj = listOfTaxCodes.find(
          (tc) => tc.txcID === row.jnsTaxCode_txcID,
        );
        const siteCodeObj = listOfSites.find(
          (site) => site.dpsID === row.jnsSite_dpsID,
        );
        return {
          ...row,
          jnsTaxCode_txcID: taxCodeObj
            ? taxCodeObj.txcCode
            : row.jnsTaxCode_txcID,
          jnsSite_dpsID: siteCodeObj ? siteCodeObj.dpsCode : row.jnsSite_dpsID,
        };
      });

    worksheet.addRows(transformedData);

    workbook.csv.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "JournalGeneral.csv",
      );
    });
  };

  const handleDeleteGeneralJournal = async () => {
    setLoading(true);
    if (!headerData?.jnlID) {
      enqueueSnackbar(`This journal is not saved, so you can't delete it`, {
        variant: "warning",
      });
      setLoading(false);
      return;
    }

    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/general-journal/delete-journal-data?journalId=${headerData.jnlID}&BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      url,
      method: "POST",
      ip: ip,
      onSuccess: (data) => {
        enqueueSnackbar("Delete successful", { variant: "success" });
        setLoading(false);

        navigate(`/${getCookie("databaseId")}/en-au/books/general-journal/`);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
        setLoading(false);
      },
    });
  };

  const areDebitAndCreditEqual = () => {
    const totalDebit = gridData.reduce(
      (sum, item) => sum + (item.jnsDr || 0),
      0,
    );
    const totalCredit = gridData.reduce(
      (sum, item) => sum + (item.jnsCr || 0),
      0,
    );
    return totalDebit === totalCredit;
  };

  const handleSave = async (isDraft) => {
    setLoading(true);
    if (!isDraft && !areDebitAndCreditEqual()) {
      enqueueSnackbar("Debit and Credit must be equal to save and post.", {
        variant: "warning",
      });
      setLoading(false);
      return;
    }

    const formattedDate = formState?.jnlDate?.format("YYYY-MM-DD");

    const sanitizedMemo = DOMPurify.sanitize(formState.jnlMemo || "");

    const journalData = {
      JournalHeader: {
        jnlID: headerData?.jnlID || 0,
        jnlCode: headerData.jnlCode,
        jnlDate: formattedDate,
        jnlAccount_accNo: headerData.jnlAccount_accNo,
        jnlAmount: headerData.jnlAmount,
        jnlMemo: sanitizedMemo,
        jnlReference: headerData.jnlReference,
        jnlContact_cntID: formState.jnlContact_cntID,
        jnlProject_prjID: headerData.jnlProject_prjID,
        jnlReviewYN: "N",
        jnlReconciled_rcnID: headerData.jnlReconciled_rcnID,
        jnlStatus: isDraft ? "D" : "F",
        jnlBankFeed_bftID: headerData.jnlBankFeed_bftID,
        jnlCreatedBy_usrID: headerData.jnlCreatedBy_usrID,
        jnlCreatedDateTime: headerData.jnlCreatedDateTime,
        jnlUpdatedBy_usrID: headerData.jnlUpdatedBy_usrID,
        jnlUpdatedDateTime: new Date().toISOString(),
        jnlImportID: headerData.jnlImportID,
        jnlFilesCounter: headerData.jnlFilesCounter,
      },
      JournalDetails: gridData
        .filter((detail) => detail.jnsDr !== null || detail.jnsCr !== null)
        .map((detail, index) => {
          const taxCodeObj = listOfTaxCodes.find(
            (tc) => tc.txcID === detail.jnsTaxCode_txcID,
          );
          return {
            jnsID: Number(detail.jnsID) || 0,
            jnsAccount_accNo: Number(detail.jnsAccount_accNo),
            jnsDr: parseFloat(detail.jnsDr).toFixed(2) || 0,
            jnsCr: parseFloat(detail.jnsCr).toFixed(2) || 0,
            jnsVaryTaxYN: detail.jnsVaryTaxYN,
            jnsTaxCode_txcID: taxCodeObj
              ? taxCodeObj.txcID
              : detail.jnsTaxCode_txcID,
            jnsTaxAmt: parseFloat(detail.jnsTaxAmt).toFixed(2),
            jnsProject_prjID: detail.jnsProject_prjID,
            jnsPrivPc: detail.jnsPrivPc || 0,
            jnsPrivAmt: parseFloat(detail.jnsPrivAmt),
            jnsNetBusAmt: parseFloat(
              (detail.jnsDr || detail.jnsCr || 0) -
                (detail.jnsPrivAmt || 0) -
                (detail.jnsTaxAmt || 0),
            ).toFixed(2),
            jnsReference: DOMPurify.sanitize(detail.jnsReference) || "",
            jnsReviewYN: detail.jnsReviewYN,
            jnsReconcile_rcnID: detail.jnsReconcile_rcnID || 0,
            jnsSite_dpsID: detail.jnsSite_dpsID || 0,
            jnsImportID: detail.jnsImportID || "",
            jnsTask_prdID: detail.jnsTask_prdID || 0,
            jnsBankFeed_bftID: detail.jnsBankFeed_bftID || "",
            jnsOrder: Number(index) + 1,
          };
        }),
    };

    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/general-journal/save-journal-data?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      ip: ip,
      body: journalData,
      onSuccess: (data) => {
        setSavedJournalId(data?.JournalHeader.jnlID);
        setPopupOpen(true);
        setLoading(false);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
        setLoading(false);
      },
    });
  };

  const handleSaveAsDraft = () => {
    handleSave(true);
  };

  const handleAddAccount = () => {
    setOpenAddAccountPopup(true);
  };

  const enterNewCellAndEdit = (e) => {
    setTimeout(() => {
      const dataGrid = e.component;
      const visibleColumns = dataGrid.getVisibleColumns();
      const currentRowIndex = dataGrid.option("focusedRowIndex");
      const currentColumnIndex = visibleColumns.findIndex(
        (col) => col.dataField === e.dataField,
      );

      let nextColumnIndex = currentColumnIndex + 1;
      if (nextColumnIndex >= visibleColumns.length) {
        nextColumnIndex = 0;
      }

      dataGrid.focus(dataGrid.getCellElement(currentRowIndex, nextColumnIndex));
      dataGrid.editCell(currentRowIndex, nextColumnIndex);
    }, 100);
  };

  const onEditorPreparing = (e) => {
    if (e.parentType === "dataRow") {
      // Common value change logic
      e.editorOptions.onValueChanged = (args) => {
        setValue(args.value, e.dataField, e.row.data.jnsID);

        if (e.dataField === "jnsAccount_accNo") {
          const account = accounts.find((acc) => acc.accNo === args.value);
          setValue(
            account?.accDefTxc_txcID || "",
            "jnsTaxCode_txcID",
            e.row.data.jnsID,
          );
          setValue(account?.accDefPriv || 0, "jnsPrivPc", e.row.data.jnsID);

          // Set out amount to either jnsDr or jnsCr
          const totalDebit = gridData.reduce(
            (sum, item) => sum + (item.jnsDr || 0),
            0,
          );
          const totalCredit = gridData.reduce(
            (sum, item) => sum + (item.jnsCr || 0),
            0,
          );

          const outAmount = Math.round((totalDebit - totalCredit) * 100) / 100;
          if (!e.row.data.jnsDr && !e.row.data.jnsCr) {
            if (outAmount > 0) {
              setValue(outAmount, "jnsCr", e.row.data.jnsID);
              setValue(null, "jnsDr", e.row.data.jnsID);
              focusAndEditCell(e.component, e.row.rowIndex, "jnsCr");
            } else {
              setValue(Math.abs(outAmount), "jnsDr", e.row.data.jnsID);
              setValue(null, "jnsCr", e.row.data.jnsID);
              focusAndEditCell(e.component, e.row.rowIndex, "jnsDr");
            }
          }
        }

        if (["jnsDr", "jnsCr", "jnsTaxCode_txcID"].includes(e.dataField)) {
          setValue(
            calculateTaxAmount({
              ...e.row.data,
              [e.dataField]: args.value,
            }),
            "jnsTaxAmt",
            e.row.data.jnsID,
          );
        }

        if (["jnsPrivPc", "jnsDr", "jnsCr"].includes(e.dataField)) {
          setValue(
            calculatePrivateAmount({
              ...e.row.data,
              [e.dataField]: args.value,
            }),
            "jnsPrivAmt",
            e.row.data.jnsID,
          );
        }

        if (e.dataField === "jnsDr" && args.value !== null) {
          setValue(null, "jnsCr", e.row.data.jnsID);
        }

        if (e.dataField === "jnsCr" && args.value !== null) {
          setValue(null, "jnsDr", e.row.data.jnsID);
        }

        if (e.dataField === "jnsTaxAmt") {
          const totalAmount = e.row.data.jnsDr || e.row.data.jnsCr || 0;
          if (args.value > totalAmount) {
            setValue(totalAmount, "jnsTaxAmt", e.row.data.jnsID);
          }
        }
      };

      // Specific logic to handle comma removal in input
      if (["jnsDr", "jnsCr"].includes(e.dataField)) {
        e.editorOptions.onInput = ({ event }) => {
          const valueWithoutCommas = event.currentTarget.value.replace(
            /,/g,
            "",
          );
          event.currentTarget.value = valueWithoutCommas;
        };
      }

      // Handle arrow keys and Enter key behavior
      e.editorOptions.onKeyDown = (args) => {
        const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
        if (arrowKeys.includes(args.event?.key)) {
          args.event.preventDefault();
          args.event.stopPropagation();
        } else if (args.event?.key === "Enter") {
          args.event.preventDefault();
          enterNewCellAndEdit(e);
        }
      };

      // Disable editing for specific fields under certain conditions
      if (e.dataField === "jnsTaxAmt") {
        e.editorOptions.disabled = e.row.data.jnsVaryTaxYN !== "Y";
      }
      if (e.dataField === "jnsPrivAmt") {
        e.editorOptions.disabled = true;
      }
    }
  };

  const focusAndEditCell = (dataGrid, rowIndex, dataField) => {
    setTimeout(() => {
      const columnIndex = dataGrid
        .getVisibleColumns()
        .findIndex((col) => col.dataField === dataField);
      if (columnIndex !== -1) {
        dataGrid.option("focusedRowIndex", rowIndex);
        dataGrid.option("focusedColumnIndex", columnIndex);
        dataGrid.editCell(rowIndex, columnIndex);
      }
    }, 250);
  };

  const onEditorPrepared = (e) => {
    if (e.parentType === "dataRow") {
      if (e.dataField === "jnsDr" && e.row.data.jnsCr !== null) {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === "jnsCr" && e.row.data.jnsDr !== null) {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === "jnsTaxAmt" && e.row.data.jnsVaryTaxYN !== "Y") {
        e.editorOptions.disabled = true;
      }
    }
  };

  const handleAddFilesButtonClick = () => {
    if (!headerData?.jnlID) {
      enqueueSnackbar(
        "You need to save the journal before you can upload files.",
        { variant: "warning" },
      );
      return;
    }
    setOpenFilesPopup(true);
  };

  const handleDuplicate = () => {
    const duplicatedData = gridData.map((item) => ({
      ...item,
      jnsID: `duplicated-${Math.random()}`,
    }));

    setGridData(duplicatedData);
    navigate(`/${getCookie("databaseId")}/en-au/books/general-journal/`);
  };

  const actionItems = [
    {
      icon: "save",
      name: "save-post",
      text: "Save & Post",
      sx: {
        backgroundColor: theme.palette.primary.main,
        borderColor: "transparent",
        color: isDarkMode ? "#000" : "#FFF",
      },
      onClick: () => handleSave(false),
    },
    {
      icon: "save",
      name: "save-draft",
      text: "Save as Draft",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleSaveAsDraft,
    },
    {
      icon: "trash",
      name: "save-delete",
      text: "Delete",
      sx: {
        backgroundColor: isDarkMode ? "#465572" : theme.typography.linkColor,
        borderColor: "transparent",
        color: "#FFF",
      },
      onClick: handleDeleteGeneralJournal,
    },
    {
      icon: "pdffile",
      name: "save-report",
      text: "Report",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: () => {},
    },
    {
      icon: "file",
      name: "save-files",
      text: "Files",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleAddFilesButtonClick,
    },
    {
      icon: "plus",
      name: "save-account",
      text: "Add Account",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleAddAccount,
    },
    {
      icon: "copy",
      name: "duplicate",
      text: "Duplicate",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleDuplicate,
      hidden: !headerData?.jnlID,
    },
  ];

  const dataSource = new CustomStore({
    key: "jnsID",
    load: () => Promise.resolve(gridData),
    update: (key, values) => {
      setGridData((prevData) =>
        prevData.map((item) =>
          item.jnsID === key ? { ...item, ...values } : item,
        ),
      );
    },
  });

  useEffect(() => {
    const styles = `
    .dx-button i {
        color: ${isDarkMode ? theme.palette.primary.main : "#000"} !important;
        }
    .dx-button-save-post i {
        color: ${isDarkMode ? "#000" : "#FFF"} !important;
      }
    .dx-button-save i {
        color: ${isDarkMode ? "#000" : "#FFF"} !important;
      }
    .dx-button.dx-button-save-delete i {
        color: #FFF !important;
      }
    .dx-button-import-export {
      background-color: ${isDarkMode ? "#353E51" : "#FFF"};
      border-color: ${isDarkMode && "transparent"};
      color: ${isDarkMode ? theme.palette.primary.main : "#000"} !important;
    }
    .dx-button.dx-button-import-export i {
      color: ${isDarkMode ? theme.palette.primary.main : "#000"} !important;
    }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [isDarkMode, theme]);

  useEffect(() => {
    setAccounts(listOfAccounts);
  }, [listOfAccounts]);

  const actionsColumn = (
    <Column
      width={58}
      caption=""
      cellRender={({ data }) => (
        <ActionMenu rowId={data.jnsID} handleDelete={handleDelete} />
      )}
    />
  );

  return (
    <>
      <BackdropLoading open={loading} />
      {openAddAccountPopup && (
        <AddAccountPopup
          taxCodes={listOfTaxCodes}
          setOpenAddAccountPopup={setOpenAddAccountPopup}
          setListOfAccounts={setAccounts}
          accounts={accounts}
        />
      )}

      <JournalSavedPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        journalId={savedJournalId}
        isGeneralJournal={true}
        reloadPage={() => {
          reloadPage();
          setGridData([]);
        }}
      />

      <Box
        sx={{
          ".dx-datagrid-total-footer": {
            "td:first-of-type": {
              padding: 0,
            },
            td: {
              minWidth: "125px",
            },
          },
          ".dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input":
            {
              color: isDarkMode ? "#FFF !important" : "#000",
            },
        }}
      >
        {openFilesPopup && (
          <FilesUploadedPopup
            journalId={headerData?.jnlID}
            setOpenFilesPopup={setOpenFilesPopup}
          />
        )}
        <div
          style={{
            marginBottom: largeScreen ? "-46px" : "10px",
            zIndex: 1,
            maxWidth: "870px",
            position: "relative",
          }}
          className="action-buttons"
        >
          {actionItems
            .filter((item) => !item.hidden)
            .map((item, index) => (
              <Button
                className={`dx-button dx-button-${item.name}`}
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  color: isDarkMode ? theme.palette.primary.main : "#000",
                  marginRight: 16,
                  ...item.sx,
                }}
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
              />
            ))}
        </div>

        <DataGrid
          showColumnLines={true}
          loadPanel={{ enabled: false, showIndicator: false, showPane: false }}
          refreshMode="repaint"
          repaintChangesOnly={true}
          ref={dataGridRef}
          onCellPrepared={handleCellPrepared}
          onEditorPreparing={onEditorPreparing}
          onEditorPrepared={onEditorPrepared}
          dataSource={dataSource}
          keyExpr="jnsID"
          showBorders={true}
          wordWrapEnabled={true}
          columnHidingEnabled={false}
          allowColumnReordering={false}
          hoverStateEnabled={true}
          cacheEnabled={true}
          errorRowEnabled={true}
        >
          <Scrolling showScrollbar="always" />
          <Paging enabled={true} pageSize={20} />
          <Pager
            allowedPageSizes={[10, 20, 50]}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Selection mode="single" />
          <Editing
            refreshMode="reshape"
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
            confirmDelete={false}
            selectTextOnEditStart={true}
            startEditAction="click"
          />
          {interfacePreference === "left" && actionsColumn}

          <Column
            dataField="jnsAccount_accNo"
            width={136}
            caption="Account"
            alignment="left"
            lookup={{
              dataSource: {
                store: accounts,
                searchExpr: ["accNo", "accName", "accType"],
              },
              valueExpr: "accNo",
              displayExpr: "accNo",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 200,
              },
              searchExpr: ["accNo", "accName"],

              searchTimeout: 0,
              itemTemplate: (data) => `
                <div style="white-space: normal; display: flex; flex-direction: column;">
                  <div><b>${data.accNo}</b></div>
                  <div>${data.accName}</div>
                  <div><i>(${data.accType})</i></div>
                </div>
              `,
            }}
          />
          <Column
            dataField="jnsDr"
            caption="Debit"
            width={125}
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsDr !== 0 ? rowData.jnsDr : ""
            }
          />
          <Column
            dataField="jnsCr"
            caption="Credit"
            width={125}
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsCr !== 0 ? rowData.jnsCr : ""
            }
          />
          <Column
            dataField="jnsTaxCode_txcID"
            caption="Tax"
            width={125}
            alignment="left"
            calculateDisplayValue={getTaxCodeDisplayValue}
            lookup={{
              dataSource: listOfTaxCodes,
              valueExpr: "txcID",
              displayExpr: "txcCode",
              searchExpr: ["txcCode", "txcID"],
            }}
            editorOptions={{
              searchExpr: ["txcCode", "txcName"],
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) => `
                <div style="white-space: normal; display: flex; flex-direction: column;">
                  <div><b>${data.txcCode}</b></div>
                  <div>${data.txcName}</div>
                  <div><i>${(data.txcRate * 100).toFixed(2)}%</i></div>
                </div>
              `,
            }}
          />
          {showPrivatePortion && (
            <Column
              dataField="jnsPrivPc"
              caption="Priv %"
              width={80}
              dataType="number"
              format={{ type: "fixedPoint", precision: 2.0 }}
              editorOptions={{
                min: 0,
                max: 99,
              }}
              validationRules={[
                {
                  type: "range",
                  min: 0,
                  max: 99,
                  message: "Priv % must be between 0 and 99",
                },
              ]}
            />
          )}
          <Column
            dataField="jnsReference"
            alignment="left"
            caption="Memo"
            dataType="text"
            minWidth={250}
          />
          <Column
            width={85}
            dataField="jnsReviewYN"
            caption="Review"
            dataType="boolean"
            calculateCellValue={(rowData) => {
              return rowData.jnsReviewYN === "Y";
            }}
            setCellValue={(newData, value) => {
              newData.jnsReviewYN = value ? "Y" : "N";
            }}
          />
          {showVaryManualTax && (
            <Column
              width={85}
              dataField="jnsVaryTaxYN"
              caption="Tax Var"
              dataType="boolean"
              calculateCellValue={(rowData) => rowData.jnsVaryTaxYN === "Y"}
              setCellValue={(newData, value) => {
                newData.jnsVaryTaxYN = value ? "Y" : "N";
              }}
            />
          )}
          <Column
            dataField="jnsTaxAmt"
            caption="Tax Amount"
            width={120}
            alignment="right"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsTaxAmt !== 0 ? rowData.jnsTaxAmt : ""
            }
            editorOptions={(cellInfo) => ({
              disabled: cellInfo.row.data.jnsVaryTaxYN !== "Y",
            })}
          />
          {showPrivatePortion && (
            <Column
              width={120}
              dataField="jnsPrivAmt"
              alignment="right"
              caption="Private Amount"
              dataType="number"
              format={{ type: "fixedPoint", precision: 2.0 }}
              calculateDisplayValue={(rowData) => {
                return rowData.jnsPrivAmt !== 0 ? rowData.jnsPrivAmt : "";
              }}
            />
          )}
          {/* <Column
            dataField="jnsNetBusAmt"
            caption="Net Bus Amt"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsNetBusAmt !== 0 ? rowData.jnsNetBusAmt : ""
            }
          /> */}
          <Column
            dataField="jnsSite_dpsID"
            alignment="left"
            caption="Site"
            width={125}
            lookup={{
              dataSource: listOfSites,
              valueExpr: "dpsID",
              displayExpr: "dpsCode",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) =>
                `<div
                  style="white-space: normal; display: flex; flex-direction: column;">
                  <div>${data.dpsCode}</div>
                  <div>${data.dpsName}</div>
                </div>`,
            }}
          />
          <Column dataField="MobileColumn" caption="Mobile" visible={false} />
          {interfacePreference !== "left" && actionsColumn}

          <Summary calculateCustomSummary={calculateCustomSummary}>
            <TotalItem column="jnsAccount_accNo" summaryType="custom" />
            <TotalItem
              column="dpsCode"
              summaryType="count"
              displayFormat="Total rows: {0}"
            />
            <TotalItem
              name="jnsDr"
              column="jnsDr"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2.0 }}
              displayFormat="Debit: {0}"
            />
            <TotalItem
              name="jnsCr"
              column="jnsCr"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2.0 }}
              displayFormat="Credit: {0}"
            />
            <TotalItem
              name="OutSummary"
              showInColumn="jnsTaxCode_txcID"
              summaryType="custom"
              valueFormat="fixedPoint"
              displayFormat="Out: {0}"
            />
          </Summary>

          <Toolbar>
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
            <Item name="searchPanel" />
            <Item
              widget="dxButton"
              location="after"
              options={{
                icon: "export",
                text: "Export",
                onClick: handleExport,
                elementAttr: { class: "dx-button dx-button-import-export" },
              }}
            />
            <Item
              widget="dxButton"
              location="after"
              options={{
                icon: "import",
                text: "Import",
                onClick: openImportPopup,
                elementAttr: { class: "dx-button dx-button-import-export" },
              }}
            />
          </Toolbar>
        </DataGrid>

        <Popup
          visible={isImportPopupVisible}
          onHiding={closeImportPopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Import CSV"
          width={400}
          height={250}
        >
          <div>
            <input
              ref={fileInputRef}
              type="file"
              accept=".csv"
              onChange={handleImportFileChange}
            />
            <Button text="Cancel" onClick={closeImportPopup} />
          </div>
        </Popup>
      </Box>
    </>
  );
};

const ActionMenu = ({ rowId, handleDelete }) => {
  const [target, setTarget] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setTarget(event.currentTarget);
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setTarget(null);
    setShowMenu(false);
  };

  return (
    <>
      <IconButton sx={{ padding: 0 }} onClick={handleMenuClick}>
        <MoreHorizIcon />
      </IconButton>
      <ContextMenu
        dataSource={[{ text: "Delete", onClick: () => handleDelete(rowId) }]}
        target={target}
        onItemClick={(e) => {
          e.itemData.onClick();
          handleMenuClose();
        }}
        visible={showMenu}
        onHiding={handleMenuClose}
        showEvent={null}
      />
    </>
  );
};

export default GeneralJournalGrid;
