import { useMutation } from "@tanstack/react-query";
import { getHeaders } from "../../../../utils/api";
import { downloadPDF } from "../../../../utils/reusable";
import { MutationConfig } from "../../../../lib/react-query";
import envConfig from "../../../../config";

export const downloadReportPDF = async ({
  body,
}: {
  body: any;
}): Promise<any> => {
  const controllerName = "accounting";
  const actionName = "get-bas-pdf-rs1";
  const api = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  const headers = getHeaders();

  console.log("DEBUGGING -> BAS -> api, body, headers: ", api, body, headers);

  const response = await fetch(api, {
    method: "POST",
    // @ts-ignore
    headers: { "Content-Type": "application/json", ...headers },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    // Throw an error to be caught by React Query error handling
    throw new Error(`Failed to download PDF", ${JSON.stringify(response)}`);
  }

  const result = await response.json();

  console.log("DEBUGGING -> BAS -> result.json(): ", result);

  if (response.ok && result?.ReportBase64 && result?.SuccessYN === "Y") {
    const isPDFDownlaoded = await downloadPDF(
      result?.ReportBase64,
      result?.FileName,
    );
    return isPDFDownlaoded;
  } else {
    console.log(
      "Error: ",
      result?.ErrorMessage || result?.errorMessage,
      ". Response: ",
      result,
    );
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

type UseDownloadPDFBASReport = {
  mutationConfig?: MutationConfig<typeof downloadReportPDF>;
};

export const useDownloadPDFBASReport = ({
  mutationConfig,
}: UseDownloadPDFBASReport = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    ...restConfig,
    onSuccess: (...args) => {
      onSuccess?.(...args);
    },
    mutationFn: downloadReportPDF,
  });
};
