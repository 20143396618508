import envConfig from "../../../../config";
import { getCookie } from "../../../../utils/api";
import { createSessionForExternal } from "../../../../utils/reusable";

// TODO: rename the file - remove get keyword
// TODO: remove the file
export const openTransactionInURL = (transID, rowType, dbId, ip, apiCall, linkTodets) => {
  const LinkToDets = linkTodets;

  const updatedLink = LinkToDets.replace("[A]", envConfig.correspondingService)
    .replace("[DbID]", getCookie("databaseId") as string)
    .replace("[Lang]", "en-au");

  const urlToUse = `https://${updatedLink}`;

  createSessionForExternal(urlToUse, ip, true, apiCall);
};
