import React, { useEffect } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";

const request = {
  scopes: ["user.read", "mail.send", "openid", "profile", "offline_access"],
  prompt: "select_account",
};

const MSAuthentication = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account && instance) {
      instance
        .acquireTokenSilent({
          ...request,
          account: account,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // Use loginRedirect with prompt: "select_account"
            instance.loginRedirect(request);
          }
        });
    }
  }, [account, instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <div>You are logged in!</div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <button onClick={() => instance.loginPopup(request)}>Log In</button>
      </UnauthenticatedTemplate>
    </>
  );
};

export default MSAuthentication;
