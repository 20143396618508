import Cookies from "js-cookie";
import md5 from "md5";
import envConfig from "../config";

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const cookieParts = cookie.split("=");
    const cookieName = cookieParts[0].trim();
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

export const getLoginSecured = (login, password) => {
  if (!login && !password && getCookie("loginSecuredHash")) {
    return getCookie("loginSecuredHash");
  }
  const loginEmail = login || getCookie("loginEmail");
  const loginPassword = password;
  const passwordFromCookies = getCookie("loginPassword");
  let hashedPassword;
  if (loginPassword) {
    hashedPassword = md5(loginPassword);
  } else {
    hashedPassword = passwordFromCookies;
  }
  if (!hashedPassword) {
    hashedPassword = passwordFromCookies;
  }
  const combinedString = `${loginEmail}:${hashedPassword}`;
  if (combinedString === "undefined:undefined") {
    console.log("abort");
    return;
  }
  const base64Encoded = btoa(combinedString);
  Cookies.set("loginSecuredHash", base64Encoded, { path: "/" });

  return base64Encoded;
};

export const getHeaders = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": "31.42.0.186",
    "olivs-api-computer-name": "BTM-WS2019",
    "olivs-root-password": process.env.REACT_APP_OLIVS_ROOT_PASSWORD,
    "session-id": getCookie("sessionId"),
    "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
  };

  if (getCookie("sessionId")) {
    return {
      ...headers,
      "browser-agent": window.navigator.userAgent,
      "session-id": getCookie("sessionId"),
    };
  }
  return headers;
};

export const default_headers = {
  Accept: "application/json",
  "olivs-api-real-ip": "31.42.0.186",
  "olivs-api-computer-name": "BTM-WS2019",
  "olivs-root-password": process.env.REACT_APP_OLIVS_ROOT_PASSWORD,
  "api-key": "OlivsAuth",
};

export const LOGIN_API_OLIVS_APP_URL = `https://${envConfig.apiLogin}/api/v1/`;

export const logoutUser = async () => {
  try {
    const response = await fetch(
      `${LOGIN_API_OLIVS_APP_URL}en-au/system/destroy-api-token-details?BaseHostURL=${envConfig.loginUrl}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "31.42.0.186",
          "olivs-api-computer-name": "BTM-WS2019",
          "session-id": getCookie("sessionId"),
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
        },
        body: JSON.stringify({}),
      },
    );

    const data = await response.json();

    if (data?.SuccessYN === "Y") {
      clearAllCookies();
    } else {
      console.error("Failed to log out:", data);
    }
  } catch (error) {
    console.error("Error during logout:", error);
  }
};
