import React, { useRef, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

const OverflowTip = ({ value, content, wrap }) => {
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={value} disableHoverListener={!hoverStatus}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: wrap ? "wrap" : "nowrap",
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
