import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { commonThemes, darkTheme, lightTheme } from "../../utils/themes/themes";
import { useTheme } from "../../context/ThemeContext";
import { useContext, useEffect, useState } from "react";
import useApiCall from "../../hooks/ApiCall";
import { getCookie, getHeaders } from "../../utils/api";
import {
  DataGridPro,
  GridCsvExportMenuItem,
  GridFilterAltIcon,
  GridToolbar,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import numeral from "numeral";
import { isEmptyOrNull, toLowerCaseKeys } from "../../utils/reusable";
import OverflowTip from "../../components/OverflowTip/OverflowTip";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useLoginPopup } from "../../context/LoginPopupContext";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { ReactComponent as FullscreenIcon } from "../../assets/full-size.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import ReportDatesContext from "../../context/ReportDatesContext";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";

const ChangeDatePopup = ({
  setOpenPopup = () => {},
  selectedRows,
  updateDateTo,
  setUpdateDateTo = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [newDate, setNewDate] = useState(updateDateTo);

  const onUpdate = () => {
    setUpdateDateTo(newDate);
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Date
        </Typography>
        <Typography
          sx={{ my: 5 }}
        >{`You are about to change date in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Change date in the selected transactions to:
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            value={dayjs(newDate)}
            maxDate={dayjs()}
            onChange={(date) => {
              const formatted = date ? dayjs(date).format("YYYY-MM-DD") : "";
              setNewDate(formatted);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <Typography sx={{ my: 5 }}>
          Make sure you want to change date in all selected transactions. You
          will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updateDateTo && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdateDateTo(null);
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={
              !newDate ||
              !dayjs(newDate).isValid() ||
              dayjs(newDate).isAfter(dayjs(), "day")
            }
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ChangePrivatePortionPopup = ({
  setOpenPopup = () => {},
  selectedRows,
  updatePrivatePortionTo,
  setUpdatePrivatePortionTo = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [newPrivatePortion, setNewPrivatePortion] = useState(
    updatePrivatePortionTo,
  );

  const onUpdate = () => {
    setUpdatePrivatePortionTo(newPrivatePortion);
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Private Portion
        </Typography>
        <Typography
          sx={{ my: 5 }}
        >{`You are about to change private portion in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Change private portion in the selected transaction details to:
        </Typography>

        <TextField
          value={newPrivatePortion}
          type="number"
          onChange={(e) => setNewPrivatePortion(e.target.value)}
          InputProps={{
            inputProps: {
              min: 0,
              max: 100,
              step: 0.01,
              style: { textAlign: "right" },
            },
            endAdornment: "%",
            sx: {
              textAlign: "right",
            },
          }}
          sx={{
            ".MuiInputBase-root": {
              borderRadius: "8px",
            },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },

            "input[type=number]": {
              MozAppearance: "textfield",
            },
            minWidth: "280px",
            maxWidth: "280px",
          }}
        />

        <Typography sx={{ my: 5 }}>
          Make sure you want to change private portion in all selected
          transactions. You will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updatePrivatePortionTo && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdatePrivatePortionTo(null);
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={newPrivatePortion < 0.01 || newPrivatePortion > 100}
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ChangeSitePopup = ({
  setOpenPopup = () => {},
  allSites,
  selectedRows,
  updateSiteTo,
  setUpdateSiteTo = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [newSite, setNewSite] = useState(updateSiteTo);
  const [clearAttachSite, setClearAttachSite] = useState(
    updateSiteTo === 0 ? "clear" : updateSiteTo.DpsID ? "attach" : null,
  );

  const onUpdate = () => {
    setUpdateSiteTo(clearAttachSite === "clear" ? 0 : newSite);
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Site
        </Typography>
        <Typography
          sx={{ my: 5 }}
        >{`You are about to change site in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Select new Site for selected transactions. Crossed-out transactions
          are not available for this change, but other changes will be applied.
        </Typography>

        <FormControl
          component="fieldset"
          sx={{
            minWidth: "fit-content",
          }}
        >
          <RadioGroup
            value={clearAttachSite}
            onChange={(e) => setClearAttachSite(e.target.value)}
            row
            sx={{
              mb: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormControlLabel
              sx={{ span: { fontSize: theme.typography.fontSize } }}
              value="clear"
              control={<Radio />}
              label="Clear site from entries"
            />
            <FormControlLabel
              sx={{ span: { fontSize: theme.typography.fontSize } }}
              value="attach"
              control={<Radio />}
              label="Attach this Site"
            />
          </RadioGroup>
        </FormControl>

        <Autocomplete
          id="contacts-autocomplete"
          options={allSites || []}
          getOptionLabel={(option) => option.DpsName || ""}
          value={clearAttachSite === "clear" ? null : newSite}
          onChange={(_, newValue) => {
            setNewSite(newValue);
          }}
          disabled={clearAttachSite === "clear"}
          isOptionEqualToValue={(option, value) => option.DpsID === value.DpsID}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Site"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                minWidth: "280px",
                maxWidth: "280px",
                marginRight: 2,
              }}
            />
          )}
        />

        <Typography sx={{ my: 5 }}>
          Make sure you want to change site in all selected transactions. You
          will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updateSiteTo?.DpsID && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdateSiteTo({});
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={!Boolean(newSite?.DpsID) && clearAttachSite !== "clear"}
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ChangeContactsPopup = ({
  setOpenPopup = () => {},
  allContacts,
  selectedRows,
  updateContactTo,
  setUpdateContactTo = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [newContact, setNewContact] = useState(updateContactTo);
  const [clearAttachContact, setClearAttachContact] = useState(
    updateContactTo === 0 ? "clear" : updateContactTo.CntID ? "attach" : null,
  );

  const onUpdate = () => {
    setUpdateContactTo(clearAttachContact === "clear" ? 0 : newContact);
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Contact
        </Typography>
        <Typography
          sx={{ my: 5 }}
        >{`You are about to change contact in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Select new contact for selected transactions. Crossed-out transactions
          are not available for this change, but other changes will be applied.
        </Typography>

        <FormControl
          component="fieldset"
          sx={{
            minWidth: "fit-content",
          }}
        >
          <RadioGroup
            value={clearAttachContact}
            onChange={(e) => setClearAttachContact(e.target.value)}
            row
            sx={{
              mb: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormControlLabel
              sx={{ span: { fontSize: theme.typography.fontSize } }}
              value="clear"
              control={<Radio />}
              label="Clear contact from entries"
            />
            <FormControlLabel
              sx={{ span: { fontSize: theme.typography.fontSize } }}
              value="attach"
              control={<Radio />}
              label="Attach this Contact"
            />
          </RadioGroup>
        </FormControl>

        <Autocomplete
          id="contacts-autocomplete"
          disabled={!Boolean(clearAttachContact === "attach")}
          options={allContacts || []}
          getOptionLabel={(option) =>
            option.CntCode ? `${option.CntCode} ${option.CntName}` : ""
          }
          value={clearAttachContact === "clear" ? null : newContact}
          onChange={(_, newValue) => {
            setNewContact(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.CntID === value.CntID}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Contact"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                minWidth: "280px",
                maxWidth: "280px",
                marginRight: 2,
              }}
            />
          )}
        />
        <Typography sx={{ my: 5 }}>
          Make sure you want to change contact in all selected transactions.
          <h3 style={{ marginTop: "3px", marginBottom: "5px" }}>
            This will change recipient in invoice and Supplier in bills!{" "}
          </h3>
          You will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updateContactTo?.CntID && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdateContactTo({});
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={
              !Boolean(newContact?.CntID) && clearAttachContact !== "clear"
            }
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ChangeTaxCodePopup = ({
  setOpenPopup = () => {},
  allTaxCodes,
  selectedRows,
  updateTaxCodeTo,
  setUpdateTaxCodeTo = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [newTaxCode, setNewTaxCode] = useState(updateTaxCodeTo);

  const onUpdate = () => {
    setUpdateTaxCodeTo(newTaxCode);
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Tax Code
        </Typography>
        <Typography
          sx={{ my: 5 }}
        >{`You are about to change tax code in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Select new tax code for selected transactions. Crossed-out
          transactions are not available for this change, but other changes will
          be applied.
        </Typography>

        <Autocomplete
          id="taxcodes-autocomplete"
          options={allTaxCodes || []}
          getOptionLabel={(option) => option.TxcName || ""}
          value={newTaxCode}
          onChange={(_, newValue) => {
            setNewTaxCode(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.TxcID === value.TxcID}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tax code"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                minWidth: "280px",
                maxWidth: "280px",
                marginRight: 2,
              }}
            />
          )}
        />

        <Typography sx={{ my: 5 }}>
          Make sure you want to change tax code in all selected transactions.
          You will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updateTaxCodeTo?.TxcID && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdateTaxCodeTo({});
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={!Boolean(newTaxCode?.TxcID)}
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ChangeVariedTaxPopup = ({
  setOpenPopup = () => {},
  selectedRows,
  updateVariedTaxTo,
  setUpdateVariedTaxTo = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [taxVariationRemove, setTaxVariationRemove] = useState(
    updateVariedTaxTo === "remove"
      ? "remove"
      : updateVariedTaxTo
        ? "apply"
        : "",
  );
  const [newTaxVariation, setNewTaxVariation] = useState(
    updateVariedTaxTo === "remove" ? "" : updateVariedTaxTo,
  );

  const onUpdate = () => {
    setUpdateVariedTaxTo(
      taxVariationRemove === "apply" ? newTaxVariation : "remove",
    );
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Varied Tax
        </Typography>
        <Typography
          sx={{ my: 5 }}
        >{`You are about to change tax variation in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Tax variation replaces default calculation of tax rate with your
          preset amount. <br />
          Amount cannot be greater than Total Amount less Private Amount
        </Typography>

        <FormControl
          component="fieldset"
          sx={{
            minWidth: "fit-content",
          }}
        >
          <RadioGroup
            value={taxVariationRemove}
            onChange={(e) => setTaxVariationRemove(e.target.value)}
            row
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FormControlLabel
              sx={{ span: { fontSize: theme.typography.fontSize } }}
              value="remove"
              control={<Radio />}
              label="Remove Tax Variation from selected transactions"
            />
            <FormControlLabel
              sx={{ span: { fontSize: theme.typography.fontSize } }}
              value="apply"
              control={<Radio />}
              label="Apply below amount as new GST amount for selected transactions"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          disabled={taxVariationRemove !== "apply"}
          value={newTaxVariation}
          type="number"
          onChange={(e) => setNewTaxVariation(e.target.value)}
          InputProps={{
            inputProps: {
              min: 0,
              step: 0.01,
              style: { textAlign: "right" },
            },
            sx: { textAlign: "right" },
          }}
          sx={{
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },

            "input[type=number]": {
              MozAppearance: "textfield",
            },
            ".MuiInputBase-root": {
              borderRadius: "8px",
            },
            minWidth: "280px",
            maxWidth: "280px",
          }}
        />

        <Typography sx={{ my: 5 }}>
          Make sure you want to change tax variation in all selected
          transactions. You will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updateVariedTaxTo && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdateVariedTaxTo(null);
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={
              taxVariationRemove === "apply"
                ? newTaxVariation < 0.01 || newTaxVariation > 99.99
                : taxVariationRemove !== "remove"
            }
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const ChangeAccountPopup = ({
  setOpenPopup = () => {},
  accountsData = {},
  selectedRows,
  updateAccNumberTo,
  setUpdateAccNumberTo = () => {},
}) => {
  const [newSelectedAccount, setNewSelectedAccount] =
    useState(updateAccNumberTo);
  const { isDarkMode } = useTheme();

  const theme = isDarkMode ? darkTheme : lightTheme;
  const accNumbers = [...(selectedRows || [])]
    .map((selected) => selected.accNo)
    .filter((val) => !!val);

  const onUpdate = () => {
    setUpdateAccNumberTo(newSelectedAccount);
    enqueueSnackbar(
      "Changes set. Click Apply Changes to commit them to Ledgers.",
      { variant: "info" },
    );
    setOpenPopup("");
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <Typography
          sx={{
            color: isDarkMode ? "#FFF" : "#252525",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "133.4%",
          }}
        >
          Change Account
        </Typography>
        <Typography sx={{ my: 5 }}>{`You are about to change accounts: ${[
          ...new Set(accNumbers),
        ].join(", ")} in ${selectedRows?.length} transactions.`}</Typography>
        <Typography sx={{ mb: 2 }}>
          Select new account for selected transaction details
        </Typography>

        {console.log("accountsData", accountsData)}
        <Autocomplete
          id="account-autocomplete"
          options={accountsData?.ListOfAccounts || []}
          getOptionLabel={(option) => `${option.accNo} ${option.accName}`}
          value={newSelectedAccount}
          onChange={(_, newValue) => {
            console.log("newValue", newValue);
            setNewSelectedAccount(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.accNo === value.accNo}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Accounts"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                minWidth: "280px",
                maxWidth: "280px",
              }}
            />
          )}
        />

        <Typography sx={{ my: 5 }}>
          Make sure you want to change accounts in all selected transactions.
          You will be asked to confirm this change.
        </Typography>
        <DialogActions>
          {updateAccNumberTo && (
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setOpenPopup("");
                setUpdateAccNumberTo("");
              }}
              variant="secondary"
            >
              Clear update action
            </Button>
          )}
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              setOpenPopup("");
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={!newSelectedAccount}
            onClick={onUpdate}
            variant="primary"
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const UpdateTableControls = ({
  setOpenPopup = () => {},
  isAnySelected,
  updateAccNumberTo,
  updateVariedTaxTo,
  updateTaxCodeTo,
  updateContactTo,
  updateSiteTo,
  updatePrivatePortionTo,
  updateDateTo,
  onSubmitChanges = () => {},
}) => {
  const { isDarkMode } = useTheme();

  const sxButton = {
    maxHeight: "35px",
    ml: 2,
    mt: 1,
    textTransform: "none",
    bgcolor: "rgba(132, 159, 35, 0.04)",
    "&.MuiButtonBase-root:hover": {
      bgcolor: "rgba(132, 159, 35, 0.08)",
    },
  };

  const onChangeClick = (title) => {
    setOpenPopup(title);
  };
  const loading = !isAnySelected;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        mt: 4,
        mb: 3,
      }}
    >
      <Typography
        sx={{
          color: isDarkMode ? "#FFF" : "#000",
          fontSize: "15px",
          fontWeight: 700,
          lineHeight: "26px",
          letterSpacing: "0.46px",
          mt: 1,
        }}
      >
        Change:
      </Typography>
      <Button
        onClick={() => onChangeClick("Date")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updateDateTo ? `Date: ${updateDateTo}` : "Date"}
      </Button>
      <Button
        onClick={() => onChangeClick("Account")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updateAccNumberTo ? `Account: ${updateAccNumberTo?.accNo}` : "Account"}
      </Button>
      <Button
        onClick={() => onChangeClick("Tax Code")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updateTaxCodeTo?.TxcID
          ? `Tax Code: ${updateTaxCodeTo?.TxcName}`
          : "Tax Code"}
      </Button>
      <Button
        onClick={() => onChangeClick("Contact")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updateContactTo?.CntID || updateContactTo === 0
          ? `Contact: ${updateContactTo?.CntName || "clear"}`
          : "Contact"}
      </Button>
      <Button
        onClick={() => onChangeClick("Site")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updateSiteTo?.DpsID || updateSiteTo === "clear"
          ? `Site: ${updateSiteTo?.DpsName || "clear"}`
          : "Site"}
      </Button>
      <Button
        onClick={() => onChangeClick("Varied Tax")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updateVariedTaxTo
          ? `Varied Tax: ${
              updateVariedTaxTo === "remove"
                ? updateVariedTaxTo
                : `${updateVariedTaxTo}`
            }`
          : "Varied Tax"}
      </Button>
      <Button
        onClick={() => onChangeClick("Private Portion")}
        disabled={loading}
        sx={sxButton}
        variant="secondary"
        disableRipple
      >
        {updatePrivatePortionTo
          ? `Private Portion: ${updatePrivatePortionTo}%`
          : "Private Portion"}
      </Button>
      <Button
        onClick={() => onSubmitChanges()}
        disabled={loading}
        sx={{
          maxHeight: "35px",
          ml: 2,
          mt: 1,
          textTransform: "none",
          "&:disabled": {
            color: isDarkMode ? "#5C5D5A" : "#B9BAB6",
            background: isDarkMode ? "#161712" : "#FAFBF6",
          },
          "&.MuiButtonBase-root:hover": {
            bgcolor: isDarkMode
              ? lightTheme.palette.primary.main
              : darkTheme.palette.primary.main,
          },
        }}
        variant="primary"
        disableRipple
      >
        Apply changes
      </Button>
    </Box>
  );
};

const FindAndRedoFilters = ({
  accountsData = {},
  dateRange,
  setDateRange = () => {},
  selectedAccounts,
  setSelectedAccounts = () => {},
  showOption,
  setShowOption = () => {},
  onApply = () => {},
}) => {
  const { isDarkMode } = useTheme();
  const { dbId, lang } = useParams();

  const [loading, setLoading] = useState(false);

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const onShow = async () => {
    setLoading(true);
    await onApply();
    setLoading(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <FormControl
        size="small"
        component="fieldset"
        sx={{ minWidth: "fit-content", mt: 2, mr: 1 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <div style={{ display: "flex" }}>
            <DatePicker
              slotProps={{
                textField: {
                  required: true,
                  variant: "outlined",
                  style: {
                    background: isDarkMode ? "unset" : "#FFF",
                  },
                },
              }}
              label="Start Date"
              value={dateRange[0]}
              maxDate={dateRange[1]}
              onChange={(newValue) =>
                handleDateChange([newValue, dateRange[1]])
              }
              sx={{
                minWidth: "130px",
                div: { borderRadius: "8px" },
                input: {
                  boxSizing: "border-box",
                  minHeight: "35px",
                  maxHeight: "35px",
                  fontSize: 12,
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                legend: {
                  span: {
                    width: "59px",
                  },
                },
              }}
            />
            <Box sx={{ mx: 1 }}> {/* Adjust margin as needed */}-</Box>
            <DatePicker
              slotProps={{
                textField: {
                  required: true,
                  variant: "outlined",
                  style: {
                    background: isDarkMode ? "unset" : "#FFF",
                  },
                },
              }}
              label="End Date"
              value={dateRange[1]}
              minDate={dateRange[0]}
              onChange={(newValue) =>
                handleDateChange([dateRange[0], newValue])
              }
              sx={{
                minWidth: "130px",
                div: { borderRadius: "8px" },
                input: {
                  boxSizing: "border-box",
                  minHeight: "35px",
                  maxHeight: "35px",
                  fontSize: 12,
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                legend: {
                  span: {
                    width: "59px",
                  },
                },
              }}
            />
          </div>
        </LocalizationProvider>
      </FormControl>

      <FormControl variant="outlined" sx={{ mt: 2, mr: 1 }}>
        <Autocomplete
          multiple
          id="accounts-autocomplete"
          options={accountsData?.ListOfAccounts || []}
          getOptionLabel={(option) => `${option.accNo} ${option.accName}`}
          value={selectedAccounts}
          onChange={(_, newValue) => {
            setSelectedAccounts(newValue);
          }}
          sx={{
            "#accounts-autocomplete": {
              height: selectedAccounts?.length > 0 ? "0 !important" : "unset",
            },
          }}
          isOptionEqualToValue={(option, value) => option.accNo === value.accNo}
          disableCloseOnSelect
          limitTags={2}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 2;

            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option.accNo}
                  />
                ))}

                {numTags > limitTags && ` +${numTags - limitTags}`}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Accounts"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                  pt: selectedAccounts?.length > 0 ? "4px !important" : "",
                  pb: selectedAccounts?.length > 0 ? "3px !important" : "",
                },
                label: {
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                },
                ".MuiAutocomplete-tag": {
                  height: "22px",
                },
                minWidth: "280px",
                maxWidth: "280px",
                marginRight: 2,
              }}
            />
          )}
        />
      </FormControl>
      {/* <FormControl variant="outlined" sx={{ mt: 2, mr: 1, width: "196px" }}>
        <InputLabel sx={{ fontSize: "12px" }}>Show</InputLabel>
        <Select
          size="small"
          sx={{ height: "35px", fontSize: "12px", maxWidth: "220px" }}
          value={showOption}
          onChange={(e) => setShowOption(e.target.value)}
          label="Reconciled"
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="reconciled">Reconciled Only</MenuItem>
          <MenuItem value="unreconciled">Unreconciled only</MenuItem>
        </Select>
      </FormControl> */}
      <Button
        onClick={() => onShow()}
        disabled={loading}
        sx={{
          mt: 2,
          maxHeight: "35px",
          background: "none",
          textTransform: "none",
          //   position: isMobile ? "relative" : "absolute",
          //   top:
          // isScreenBelow1050px && !isMobile ? "calc(100% - 20px)" : "50%",
          //   left: isScreenBelow1050px ? "unset" : "870px",
          //   right: isScreenBelow1050px && !isMobile ? "150px" : "unset",
          //   transform: isMobile ? "unset" : "translateY(-50%)",
          "&.MuiButtonBase-root:hover": {
            bgcolor: "rgba(132, 159, 35, 0.04)",
          },
        }}
        variant="secondary"
        disableRipple
        // disabled={loading}
      >
        Show transactions
      </Button>
    </Box>
  );
};

const FindAndRedo = () => {
  const navigate = useNavigate();
  const tableRef = useGridApiRef();
  const { dbId, lang } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen } = useLoginPopup();

  const { apiCall } = useApi();
  const ip = useGetIP();
  const [openFullscreen, setOpenFullscreen] = useState(false);

  const url = `https://${envConfig.apiDev2}/api/en-au/front-end/get-dropdown-lists-data?BaseHostURL=${envConfig.mainServiceUrl}`;
  const headers = getHeaders();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [accountsData, setAccountsData] = useState(null);
  const [accountsError, setAccountsError] = useState(null);
  const [accountsLoading, setAccountsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await apiCall({
        url: url,
        method: "POST",
        headers,
        body: {
          Active_Everything_Not: "N",
          AccountsYN: "Y",
          AccountsActiveOnlyYN: "Y",
          AccountsPostableOnlyAllExcludeOAE: "A",
          AccountsTypesList_emptyForAll: [],
          TaxCodesYN: "Y",
          SitesYN: "Y",
          SitesActiveOnlyYN: "N",
          ContactsYN: "Y",
          ContactsActiveOnlyYN: "N",
        },
        onSuccess: (result) => {
          setData(result);
          setLoading(false);
        },
        onError: (errorMessage) => {
          enqueueSnackbar(errorMessage, { variant: "error" });
          setLoading(false);
        },
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAccountsData = async () => {
      setAccountsLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/get-selection-list?activeYN=Y&postableYN=N&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        headers,
        onSuccess: (result) => {
          setAccountsData(result);
          setAccountsLoading(false);
        },
        onError: (errorMessage) => {
          enqueueSnackbar(errorMessage, { variant: "error" });
          setAccountsLoading(false);
        },
      });
    };

    fetchAccountsData();
  }, []);

  const { isDarkMode } = useTheme();
  const { reportDates } = useContext(ReportDatesContext);

  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [showOption, setShowOption] = useState("all");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [uniqueTwoLetters, setUniqueTwoLetters] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [displayOnlySelectedRecords, setDisplayOnlySelectedRecords] =
    useState(false);
  const [displayOnlyDisabledRecords, setDisplayOnlyDisabledRecords] =
    useState(false);
  const [snapshotFilters, setSnapshotFilters] = useState({
    displayOnlySelectedRecords: false,
    displayOnlyDisabledRecords: false,
    selectedLetters: [],
  });
  const [appliedFilters, setAppliedFilters] = useState({
    displayOnlySelectedRecords: false,
    displayOnlyDisabledRecords: false,
    selectedLetters: [],
  });

  const [transactions, setTransactions] = useState({});
  const [openPopup, setOpenPopup] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [notUpdateableRows, setNotUpdateableRows] = useState([]);
  const [updateableRows, setUpdateableRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openFilters, setOpenFilters] = useState(false);
  const [updateAccNumberTo, setUpdateAccNumberTo] = useState(null);
  const [updateVariedTaxTo, setUpdateVariedTaxTo] = useState(null);
  const [updateTaxCodeTo, setUpdateTaxCodeTo] = useState({});
  const [updateContactTo, setUpdateContactTo] = useState({});
  const [updateSiteTo, setUpdateSiteTo] = useState({});
  const [updatePrivatePortionTo, setUpdatePrivatePortionTo] = useState(null);
  const [updateDateTo, setUpdateDateTo] = useState(null);
  const prefix = `/${dbId}/${lang}/`;
  const theme = isDarkMode ? darkTheme : lightTheme;

  const apiUrl = `https://${envConfig.apiDev2}/api/en-au/accounting/find-redo-get-transactions-list?BaseHostURL=${envConfig.mainServiceUrl}`;

  const fetchData = async () => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];
    let sYear, sMonth, sDay;
    let eYear, eMonth, eDay;

    if (startDate) {
      if (startDate?.$d) {
        sYear = startDate?.year();
        sMonth = startDate?.month() + 1;
        sDay = startDate?.date();
      } else {
        sYear = startDate?.getFullYear();
        sMonth = startDate?.getMonth() + 1;
        sDay = startDate?.getDate();
      }

      sMonth = sMonth < 10 ? "0" + sMonth : sMonth;
      sDay = sDay < 10 ? "0" + sDay : sDay;
    }

    const formattedStartDate = `${sYear}-${sMonth}-${sDay}`;

    if (endDate) {
      if (endDate.$d) {
        eYear = endDate.year();
        eMonth = endDate.month() + 1;
        eDay = endDate.date();
      } else {
        eYear = endDate.getFullYear();
        eMonth = endDate.getMonth() + 1;
        eDay = endDate.getDate();
      }

      eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
      eDay = eDay < 10 ? "0" + eDay : eDay;
    }
    const formattedEndDate = `${eYear}-${eMonth}-${eDay}`;

    await apiCall({
      ip: ip,
      url: apiUrl,
      method: "POST",
      body: {
        Acc201_GridPack: "string",
        StartDate: startDate ? formattedStartDate : "",
        EndDate: endDate ? formattedEndDate : "",
        ReconciliationStatus_3All_2Rec_1Unrec: 3,
        // showOption === "all" ,
        //  ? 3 : showOption === "reconciled" ? 2 : 1,
        AccountsList: selectedAccounts?.map((acc) => acc?.accNo),
      },
      onSuccess: (data) => {
        setTransactions(data);
      },
      onError: (errorMessage) => {
        setTransactions({});
        enqueueSnackbar(
          errorMessage || "Something went wrong while fetching transactions",
          { variant: "error" },
        );
      },
    });
  };

  const handleCheckboxChange = (letter) => {
    if (selectedLetters.includes(letter)) {
      setSelectedLetters(
        selectedLetters.filter((selectedLetter) => selectedLetter !== letter),
      );
    } else {
      setDisplayOnlyDisabledRecords(false);
      setDisplayOnlySelectedRecords(false);
      setSelectedLetters([...selectedLetters, letter]);
    }
  };

  const onSubmitChanges = async () => {
    if (!selectedRows?.length) return;

    const bodyData = {
      Acc202: "string",
      DateYN: updateDateTo !== null ? "Y" : "N",
      NewDate: updateDateTo,
      AccYN: updateAccNumberTo !== null ? "Y" : "N",
      AccNo: updateAccNumberTo?.accNo,
      TaxYN: updateTaxCodeTo?.TxcID ? "Y" : "N",
      TaxID: updateTaxCodeTo?.TxcID,
      ContactYN: updateContactTo?.CntID || updateContactTo === 0 ? "Y" : "N",
      ContactID: updateContactTo?.CntID || 0,
      SiteYN: updateSiteTo?.DpsID || updateSiteTo === 0 ? "Y" : "N",
      SiteID: updateSiteTo === 0 ? 0 : updateSiteTo?.DpsID,
      VaryTaxYesNoClearYNC:
        updateVariedTaxTo === "remove" ? "C" : updateVariedTaxTo ? "Y" : "N",
      TaxAmount: updateVariedTaxTo,
      PrivPortionYN: updatePrivatePortionTo ? "Y" : "N",
      PrivPortionRate: updatePrivatePortionTo,
      SelectedTransactions: selectedRows?.map(
        ({ GUID, Reconciled, BankFeed }) => ({
          GUID,
          Reconciled,
          BankFeed,
        }),
      ),
    };

    const url = `https://${envConfig.apiDev2}/api/en-au/accounting/find-redo-update-transactions?BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      ip: ip,
      url,
      method: "POST",
      body: bodyData,
      onSuccess: async () => {
        await fetchData();
        setUpdateAccNumberTo(null);
        setUpdateContactTo({});
        setUpdateDateTo(null);
        setUpdatePrivatePortionTo(null);
        setUpdateSiteTo({});
        setUpdateTaxCodeTo({});
        setUpdateVariedTaxTo(null);
        setSelectedRows([]);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(
          errorMessage || "Something went wrong, please try again later.",
          { variant: "error" },
        );
      },
    });
  };

  useEffect(() => {
    if (!transactions.DataDT?.length) {
      setRows([]);
      return;
    }
    setIsLoading(true);
    // If there are no filters, set rows to all transactionsTD
    if (
      !appliedFilters.selectedLetters.length &&
      !appliedFilters.displayOnlySelectedRecords &&
      !appliedFilters.displayOnlyDisabledRecords
    ) {
      setRows(
        transactions.DataDT.map((row, index) => ({
          id: index,
          ...row,
        })),
      );
      setIsLoading(false);
      return;
    }

    let filteredRows = [
      ...transactions.DataDT.map((row, index) => ({
        id: index,
        ...row,
      })),
    ];
    if (appliedFilters.selectedLetters.length) {
      filteredRows = filteredRows.filter((transaction) =>
        appliedFilters.selectedLetters.some((letter) =>
          transaction.TrID.startsWith(letter),
        ),
      );
    }

    // If displayOnlySelectedRecords is true, filter rows to only include selected records
    if (appliedFilters.displayOnlySelectedRecords) {
      if (selectedRows.length > 0) {
        filteredRows = filteredRows.filter((transaction) =>
          selectedRows.some((selectedRow) => selectedRow.id === transaction.id),
        );
      } else {
        setDisplayOnlySelectedRecords(false);
        setAppliedFilters({
          ...appliedFilters,
          displayOnlySelectedRecords: false,
        });
        setSnapshotFilters({
          ...snapshotFilters,
          displayOnlySelectedRecords: false,
        });
        setRows(
          transactions.DataDT.map((row, index) => ({
            id: index,
            ...row,
          })),
        );
        setIsLoading(false);
        return;
      }
    }
    if (appliedFilters.displayOnlyDisabledRecords) {
      if (!selectedRows.length) {
        console.log("1");
        setDisplayOnlyDisabledRecords(false);
        setAppliedFilters({
          ...appliedFilters,
          displayOnlyDisabledRecords: false,
        });
        setSnapshotFilters({
          ...snapshotFilters,
          displayOnlyDisabledRecords: false,
        });
        setRows(
          transactions.DataDT.map((row, index) => ({
            id: index,
            ...row,
          })),
        );
        setIsLoading(false);
        return;
      } else if (notUpdateableRows.length > 0) {
        const isAnyNotUpdateableInSelected = notUpdateableRows.some(
          (notUpdateableRow) =>
            selectedRows.some(
              (selectedRow) => selectedRow.id === notUpdateableRow.id,
            ),
        );
        if (
          selectedRows.length > 0 &&
          notUpdateableRows.length > 0 &&
          isAnyNotUpdateableInSelected
        )
          filteredRows = filteredRows.filter(
            (transaction) =>
              notUpdateableRows.some(
                (cannotUpdateRecord) =>
                  cannotUpdateRecord.id === transaction.id,
              ) &&
              selectedRows.some(
                (selectedRow) => selectedRow.id === transaction.id,
              ),
          );
      } else {
        setDisplayOnlyDisabledRecords(false);
        setAppliedFilters({
          ...appliedFilters,
          displayOnlyDisabledRecords: false,
        });
        setSnapshotFilters({
          ...snapshotFilters,
          displayOnlyDisabledRecords: false,
        });
        setRows(
          transactions.DataDT.map((row, index) => ({
            id: index,
            ...row,
          })),
        );
        setIsLoading(false);
        return;
      }
    }
    setRows(filteredRows);
    setIsLoading(false);
  }, [appliedFilters, selectedRows, transactions]);

  useEffect(() => {
    if (transactions?.DataDT?.length > 0) {
      const uniqueFirstTwoLetters = new Set();
      transactions.DataDT.forEach((item) => {
        if (item.TrID && item.TrID.length >= 2) {
          uniqueFirstTwoLetters.add(item.TrID.substring(0, 2));
        }
      });

      const uniqueFirstTwoLettersArray = Array.from(uniqueFirstTwoLetters);
      setSelectedLetters([]);
      setUniqueTwoLetters(uniqueFirstTwoLettersArray);
    } else {
      setRows([]);
    }

    if (transactions?.ColumnsList?.length > 0) {
      const cols = transactions?.ColumnsList.map((column) => {
        return {
          field: column.RpcDataSource,
          headerName: column.RpcHeader,
          headerClassName:
            column.RpcAlign === 1
              ? "header-align-left grid-header-title "
              : column.rpcAlign === 2
                ? "header-align-center grid-header-title "
                : "header-align-right grid-header-title ",
          minWidth:
            column.RpcHeader === "ID" || column.RpcHeader === "Tax Code"
              ? 100
              : column.RpcHeader === "Account"
                ? 110
                : column.RpcHeader === "Description"
                  ? 400
                  : 140,
          maxWidth: column.RpcHeader === "ID" ? 100 : "unset",
          align:
            column.RpcAlign === 1
              ? "left"
              : column.RpcAlign === 2
                ? "center"
                : "right",
          // sortable: column.RpcSort === "Y",
          sortable: false,
          wrapText: column.RpcWrapText === "Y",
          // resizable: column.RpcAllowResizing === "Y",
          resizable: false,
          disableColumnMenu: true,
          flex: 1,
          renderCell: (params) => {
            const rowToLowercase = toLowerCaseKeys(params.row);
            const rpcDataSource = column.RpcDataSource;
            if (
              rowToLowercase[rpcDataSource.toLowerCase()] === "(cannot update)"
            ) {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <svg
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  >
                    <line
                      x1="0"
                      y1="0"
                      x2="100%"
                      y2="100%"
                      style={{
                        stroke: isDarkMode
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                        strokeWidth: 1,
                      }}
                    />
                    <line
                      x1="100%"
                      y1="0"
                      x2="0"
                      y2="100%"
                      style={{
                        stroke: isDarkMode
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                        strokeWidth: 1,
                      }}
                    />
                  </svg>
                </div>
              );
            }
            if (rowToLowercase[rpcDataSource.toLowerCase()] === "N") {
              return <div>No</div>;
            }
            if (rowToLowercase[rpcDataSource.toLowerCase()] === "Y") {
              <div>Yes</div>;
            }
            if (rpcDataSource.toLowerCase() === "trdate") {
              const date = new Date(params.value);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              return (
                <OverflowTip
                  value={`${year}-${month}-${day}`}
                  content={`${year}-${month}-${day}`}
                />
              );
            }
            if (rpcDataSource.toLowerCase() === "trid") {
              <OverflowTip
                value={rowToLowercase[rpcDataSource.toLowerCase()]}
                content={rowToLowercase[rpcDataSource.toLowerCase()]}
              />;
            }
            if (rpcDataSource.toLowerCase() === "accno") {
              return (
                <OverflowTip
                  value={rowToLowercase[rpcDataSource.toLowerCase()]}
                  content={rowToLowercase[rpcDataSource.toLowerCase()]}
                />
              );
            }
            if (rpcDataSource.toLowerCase() === "memo") {
              return (
                <OverflowTip
                  value={rowToLowercase[rpcDataSource.toLowerCase()]}
                  content={rowToLowercase[rpcDataSource.toLowerCase()]}
                  wrap={true}
                />
              );
            }
            return (
              <OverflowTip
                value={
                  typeof rowToLowercase[rpcDataSource.toLowerCase()] ===
                  "number"
                    ? rowToLowercase[rpcDataSource.toLowerCase()] < 0
                      ? `(${numeral(
                          Math.abs(rowToLowercase[rpcDataSource.toLowerCase()]),
                        ).format("0,0.00")})`
                      : rowToLowercase[rpcDataSource.toLowerCase()] === 0 ||
                          rowToLowercase[rpcDataSource.toLowerCase()] === "0"
                        ? "-"
                        : `${numeral(
                            Math.abs(
                              rowToLowercase[rpcDataSource.toLowerCase()],
                            ),
                          ).format("0,0.00")}`
                    : rowToLowercase[rpcDataSource.toLowerCase()]
                }
                content={
                  typeof rowToLowercase[rpcDataSource.toLowerCase()] ===
                  "number"
                    ? rowToLowercase[rpcDataSource.toLowerCase()] < 0
                      ? `(${numeral(
                          Math.abs(rowToLowercase[rpcDataSource.toLowerCase()]),
                        ).format("0,0.00")})`
                      : rowToLowercase[rpcDataSource.toLowerCase()] === 0 ||
                          rowToLowercase[rpcDataSource.toLowerCase()] === "0"
                        ? "-"
                        : `${numeral(
                            Math.abs(
                              rowToLowercase[rpcDataSource.toLowerCase()],
                            ),
                          ).format("0,0.00")}`
                    : rowToLowercase[rpcDataSource.toLowerCase()]
                }
              />
            );
          },
        };
      });
      setColumns(cols);
    } else {
      setColumns([]);
    }
  }, [transactions, isDarkMode]);

  const updateabilityStatus = () => {
    const fullyUpdateableRecords = [];
    const cannotUpdateRecords = [];
    if (!selectedRows.length) {
      setUpdateableRows([]);
      setNotUpdateableRows([]);
      return;
    }

    const isOtherFieldsUpdatable =
      updateVariedTaxTo !== null ||
      Object.keys(updateSiteTo).length !== 0 ||
      updatePrivatePortionTo !== null;

    selectedRows.forEach((record) => {
      let isUpdateable = true;

      // Check if Account number is updatable
      if (updateAccNumberTo !== null) {
        if (record.Reconciled === "Y" || record.BankFeed === "Y") {
          isUpdateable = false;
        }
      }

      // Check if all other fields are updatable
      if (isUpdateable && isOtherFieldsUpdatable) {
        if (
          (updateVariedTaxTo !== null && record.Tax === "(cannot update)") ||
          (updateSiteTo !== null && record.SiteCode === "(cannot update)") ||
          (updatePrivatePortionTo !== null && record.Priv === "(cannot update)")
        ) {
          isUpdateable = false;
        }
      }

      if (isUpdateable) {
        fullyUpdateableRecords.push(record);
      } else {
        cannotUpdateRecords.push(record);
      }
    });
    setUpdateableRows(fullyUpdateableRecords);
    setNotUpdateableRows(cannotUpdateRecords);
  };

  useEffect(() => {
    updateabilityStatus();
  }, [
    selectedRows,
    updateAccNumberTo,
    updatePrivatePortionTo,
    updateSiteTo,
    updateVariedTaxTo,
  ]);

  useEffect(() => {
    if (reportDates?.reportsStartDate) {
      if (reportDates?.reportsEndDate) {
        const formattedStartDate = new Date(reportDates?.reportsStartDate + "Z")
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(reportDates?.reportsEndDate + "Z")
          .toISOString()
          .split("T")[0];

        const dayjsStartDate = dayjs(formattedStartDate);
        const dayjsEndDate = dayjs(formattedEndDate);
        setDateRange([dayjsStartDate, dayjsEndDate]);
        return;
      }
      const formattedStartDate = new Date(reportDates?.reportsStartDate + "Z")
        .toISOString()
        .split("T")[0];

      const dayjsStartDate = dayjs(formattedStartDate);
      setDateRange([dayjsStartDate, null]);
    } else if (reportDates?.reportsEndDate) {
      const formattedEndDate = new Date(reportDates?.reportsEndDate + "Z")
        .toISOString()
        .split("T")[0];
      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([null, dayjsEndDate]);
    }
  }, [reportDates]);

  const GridToolBar = (props) => {
    if (!rows.length) return;
    return (
      <Box className="print-hide">
        <GridToolbarContainer>
          <GridToolbarExport />
          <GridToolbarQuickFilter {...props.quickFilterProps} />
          <Box>
            <GridFilterAltIcon
              onClick={() => setOpenFilters(true)}
              color="primary"
              sx={{ cursor: "pointer", ml: 1 }}
            />
          </Box>
          <Box
            sx={{
              mx: 2,
              cursor: "pointer",
              fontWeight: displayOnlySelectedRecords ? 700 : "normal",
            }}
            onClick={() => {
              if (isLoading) return;
              if (!displayOnlySelectedRecords) {
                setDisplayOnlySelectedRecords(true);
                setDisplayOnlyDisabledRecords(false);
                setSelectedLetters([]);
                setAppliedFilters({
                  displayOnlyDisabledRecords: false,
                  displayOnlySelectedRecords: true,
                  selectedLetters: [],
                });
              } else {
                setDisplayOnlyDisabledRecords(false);
                setAppliedFilters({
                  displayOnlyDisabledRecords: false,
                  displayOnlySelectedRecords: false,
                  selectedLetters: [],
                });
              }
            }}
          >
            {selectedRows?.length > 0 &&
              `Total Selected: ${selectedRows.length}`}
          </Box>
          <Box
            sx={{
              cursor: "pointer",
              fontWeight: displayOnlyDisabledRecords ? 700 : "normal",
            }}
            onClick={() => {
              if (isLoading) return;
              if (!displayOnlyDisabledRecords) {
                setDisplayOnlyDisabledRecords(true);
                setDisplayOnlySelectedRecords(false);
                setSelectedLetters([]);
                setAppliedFilters({
                  displayOnlyDisabledRecords: true,
                  displayOnlySelectedRecords: false,
                  selectedLetters: [],
                });
              } else {
                setDisplayOnlyDisabledRecords(false);
                setAppliedFilters({
                  displayOnlyDisabledRecords: false,
                  displayOnlySelectedRecords: false,
                  selectedLetters: [],
                });
              }
            }}
          >
            {notUpdateableRows?.length > 0 &&
              `Selected with impossible actions: ${notUpdateableRows.length}`}
          </Box>
          <Box
            sx={{
              flex: 1,
              textAlign: "right",
            }}
          >
            <Button
              sx={{ fontWeight: 700, float: "right" }}
              onClick={() => setOpenFullscreen((prev) => !prev)}
            >
              <FullscreenIcon style={{ marginRight: "8px" }} />
              Full Screen
            </Button>
            <Box
              sx={{
                color: theme.palette.primary.main,
                float: "right",
                mr: 2,
                width: "fit-content",
                li: {
                  fontWeight: 700,
                  fontSize: "14px",
                },
              }}
            >
              <GridCsvExportMenuItem />
            </Box>
          </Box>
        </GridToolbarContainer>
      </Box>
    );
  };

  useEffect(() => {
    if (openFilters) {
      setSnapshotFilters({
        displayOnlySelectedRecords: displayOnlySelectedRecords,
        displayOnlyDisabledRecords: displayOnlyDisabledRecords,
        selectedLetters: selectedLetters,
      });
    }
  }, [openFilters]);

  return (
    <Box>
      <Dialog
        open={openFilters}
        onClose={() => {
          setSelectedLetters(snapshotFilters.selectedLetters);
          setDisplayOnlyDisabledRecords(
            snapshotFilters.displayOnlyDisabledRecords,
          );
          setDisplayOnlySelectedRecords(
            snapshotFilters.displayOnlySelectedRecords,
          );
          setOpenFilters(false);
        }}
      >
        <DialogContent sx={{ p: 4 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
            Transactions
          </Typography>
          {uniqueTwoLetters.map((letter) => (
            <Box sx={{ ml: 1 }} key={letter}>
              <FormControlLabel
                sx={{ span: { fontSize: 14 } }}
                control={
                  <Checkbox
                    name={letter}
                    checked={selectedLetters.includes(letter)}
                    onChange={() => handleCheckboxChange(letter)}
                  />
                }
                label={letter}
              />
            </Box>
          ))}
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
              Other filters
            </Typography>
            <Box>
              <FormControlLabel
                sx={{ span: { fontSize: 14 } }}
                disabled={selectedRows?.length === 0}
                control={
                  <Checkbox
                    sx={{ ml: 1 }}
                    name="selectedRecords"
                    checked={displayOnlySelectedRecords}
                    onChange={() => {
                      if (!displayOnlySelectedRecords) {
                        setDisplayOnlySelectedRecords(true);
                        setDisplayOnlyDisabledRecords(false);
                        setSelectedLetters([]);
                      } else {
                        setDisplayOnlySelectedRecords(false);
                      }
                    }}
                  />
                }
                label={"Display all selected records"}
              />
            </Box>
            <FormControlLabel
              sx={{ span: { fontSize: 14 } }}
              control={
                <Checkbox
                  sx={{ ml: 1 }}
                  name="disabledRecords"
                  disabled={!Boolean(notUpdateableRows.length > 0)}
                  checked={displayOnlyDisabledRecords}
                  onChange={() => {
                    if (!displayOnlyDisabledRecords) {
                      setDisplayOnlyDisabledRecords(true);
                      setDisplayOnlySelectedRecords(false);
                      setSelectedLetters([]);
                    } else {
                      setDisplayOnlyDisabledRecords(false);
                    }
                  }}
                />
              }
              label={"Display only selected with impossible actions"}
            />
          </Box>
          <Box sx={{ float: "right" }}>
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={() => {
                setSelectedLetters(snapshotFilters.selectedLetters);
                setDisplayOnlyDisabledRecords(
                  snapshotFilters.displayOnlyDisabledRecords,
                );
                setDisplayOnlySelectedRecords(
                  snapshotFilters.displayOnlySelectedRecords,
                );
                setOpenFilters(false);
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: theme.palette.primary.main,
                },
              }}
              onClick={() => {
                setAppliedFilters({
                  displayOnlyDisabledRecords: displayOnlyDisabledRecords,
                  displayOnlySelectedRecords: displayOnlySelectedRecords,
                  selectedLetters: selectedLetters,
                });
                setOpenFilters(false);
              }}
              variant="primary"
            >
              OK
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {openPopup === "Account" && (
        <ChangeAccountPopup
          setOpenPopup={setOpenPopup}
          updateAccNumberTo={updateAccNumberTo}
          setUpdateAccNumberTo={setUpdateAccNumberTo}
          accountsData={accountsData}
          selectedRows={selectedRows}
        />
      )}
      {openPopup === "Varied Tax" && (
        <ChangeVariedTaxPopup
          setOpenPopup={setOpenPopup}
          updateVariedTaxTo={updateVariedTaxTo}
          setUpdateVariedTaxTo={setUpdateVariedTaxTo}
          selectedRows={selectedRows}
        />
      )}
      {openPopup === "Tax Code" && (
        <ChangeTaxCodePopup
          setOpenPopup={setOpenPopup}
          allTaxCodes={data?.TaxCodes}
          selectedRows={selectedRows}
          updateTaxCodeTo={updateTaxCodeTo}
          setUpdateTaxCodeTo={setUpdateTaxCodeTo}
        />
      )}
      {openPopup === "Contact" && (
        <ChangeContactsPopup
          setOpenPopup={setOpenPopup}
          allContacts={data?.Contacts}
          selectedRows={selectedRows}
          updateContactTo={updateContactTo}
          setUpdateContactTo={setUpdateContactTo}
        />
      )}
      {openPopup === "Site" && (
        <ChangeSitePopup
          setOpenPopup={setOpenPopup}
          allSites={data?.Sites}
          selectedRows={selectedRows}
          updateSiteTo={updateSiteTo}
          setUpdateSiteTo={setUpdateSiteTo}
        />
      )}
      {openPopup === "Private Portion" && (
        <ChangePrivatePortionPopup
          setOpenPopup={setOpenPopup}
          selectedRows={selectedRows}
          updatePrivatePortionTo={updatePrivatePortionTo}
          setUpdatePrivatePortionTo={setUpdatePrivatePortionTo}
        />
      )}
      {openPopup === "Date" && (
        <ChangeDatePopup
          setOpenPopup={setOpenPopup}
          selectedRows={selectedRows}
          updateDateTo={updateDateTo}
          setUpdateDateTo={setUpdateDateTo}
        />
      )}
      <PageTitle
        title="Find & Update"
        displayBackIcon={true}
        onBack={() => navigate(-1)}
      />
      <FindAndRedoFilters
        dateRange={dateRange}
        setDateRange={setDateRange}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
        showOption={showOption}
        setShowOption={setShowOption}
        onApply={fetchData}
        accountsData={accountsData}
      />
      <UpdateTableControls
        setOpenPopup={setOpenPopup}
        updateAccNumberTo={updateAccNumberTo}
        updateVariedTaxTo={updateVariedTaxTo}
        updateTaxCodeTo={updateTaxCodeTo}
        updateContactTo={updateContactTo}
        updateSiteTo={updateSiteTo}
        updatePrivatePortionTo={updatePrivatePortionTo}
        updateDateTo={updateDateTo}
        isAnySelected={rows?.length > 0}
        onSubmitChanges={onSubmitChanges}
      />
      <Box
        sx={{
          transition: "all 1s ease",
          width: openFullscreen ? "calc(100vw - 16px)" : "100%",
          position: "relative",
          left: openFullscreen ? "50%" : 0,
          right: openFullscreen ? "50%" : 0,
          marginLeft: openFullscreen ? "-50vw" : 0,
          marginRight: openFullscreen ? "-50vw" : 0,
          ".MuiBox-root > .MuiDataGrid-root": {
            marginTop: 0,
          },
          // ".MuiDataGrid-row": {
          //   maxHeight: "unset !important",
          // },
          // ".MuiDataGrid-cell": {
          //   maxHeight: "none !important",
          // },
        }}
      >
        <DataGridPremium
          localeText={{
            toolbarExportCSV: (
              <>
                <DownloadIcon style={{ marginRight: "8px" }} />
                To Excel
              </>
            ),
          }}
          disableRowSelectionOnClick={true}
          checkboxSelection={true}
          loading={isLoading}
          apiRef={tableRef}
          getRowHeight={() => "auto"}
          slots={{ toolbar: GridToolBar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                variant: "outlined",
                size: "small",
                // debounceMs: 100,
              },
            },
          }}
          rowSelectionModel={selectedRows.map((row) => row.id)}
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = transactions.DataDT.map((row, index) => ({
              id: index,
              ...row,
            })).filter((row) => selectedIDs.has(Number(row.id)));
            setSelectedRows(selectedRowData || []);
          }}
          getCellClassName={() => "column-border"}
          // slots={{
          //   toolbar: () => <></>,
          // }}
          keepNonExistentRowsSelected={true}
          columns={columns?.length > 0 && rows?.length > 0 ? columns : []}
          rows={columns?.length > 0 && rows?.length > 0 ? rows : []}
          rowHeight={40}
          hideFooter={true}
          columnVisibilityModel={{
            GUID: false,
          }}
          getRowClassName={(params) => {
            switch (params.row.RowType) {
              case "H":
                return "bold";
              case "D":
                return `indent ${
                  isDarkMode
                    ? "underline-thicker-dark"
                    : "underline-thicker-light"
                }`;
              case "F":
                return "bold underline";
              case "S":
                return "spaceholder";
              default:
                return "";
            }
          }}
          sx={{
            ".MuiDataGrid-pinnedColumnHeaders": {
              background: isDarkMode ? "#121212 !important" : "#FFF !important",
            },
            ".MuiDataGrid-pinnedColumns": {
              background: isDarkMode ? "#121212 !important" : "#FFF !important",
            },
            ".MuiDataGrid-main": {
              // maxWidth: "calc(100% - 230px)",
            },
            "div.MuiDataGrid-virtualScroller": {
              // maxWidth: "calc(100% - 230px)",
              // overflowY: "auto",
              minWidth: "100%",
              maxHeight: "82vh",
              minHeight: columns?.length === 0 ? "50px" : "unset",
              paddingTop: columns?.length === 0 ? "25px" : 0,
              // ">div": {
              //   height: "401px !important",
              // },
            },
            "div.MuiDataGrid-columnHeaders": {
              // maxWidth: columns?.length > 0 ? "calc(100% - 230px)" : "unset",
              display: columns?.length > 0 ? "flex" : "none",
            },
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
            border: "none",
            borderRadius: "8px",
            mt: "10px",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            ".MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none !important",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FindAndRedo;
