import { useQuery } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { EXAMPLE_GET_REPORTS_PARAMETERS_RESPONSE } from "./example-response";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

const REPORTS_PARAMETERS_QUERY_KEY = "reports-parameters";

// 'single-selection'
export const getReportsParameters = async (reportId: string, handleOpen: any) => {
  if (process.env.NODE_ENV === "development") {
    return EXAMPLE_GET_REPORTS_PARAMETERS_RESPONSE;
  }
  const headers = getHeaders();
  const api = `https://${envConfig.apiDev2}/api/en-au/reports/get-grid-report-parameters-rs1?ReportID=${reportId}`;

  const response = await fetch(api, {
    method: "GET",
    // @ts-ignore
    headers: {
      ...headers,
      // ReportID: reportId,
    },
  });

  if (response.status === 403 || response.status === 401) {
    setTimeout(async () => {
      handleOpen();
      // window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
    }, 1000);
    throw new Error("Your session has expired, please log back in.");
  }

  if (api.includes(envConfig.apiDev1) || api.includes(envConfig.apiDev2)) {
    checkAndUpdateApiKeyExpiration();
  }

  const result = await response.json();

  if (response.ok && result?.SuccessYN === "Y") {
    return result;
  } else {
    console.log(
      "Error: ",
      result?.ErrorMessage || result?.errorMessage,
      ". Response: ",
      result,
    );
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

export const useReportsParameters = (reportId) => {
  const { handleOpen } = useLoginPopup();

  return useQuery({
    queryKey: [REPORTS_PARAMETERS_QUERY_KEY, reportId],
    queryFn: () => getReportsParameters(reportId, handleOpen),
  });
};
