import React, { useEffect, useState } from "react";
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Grouping,
  SearchPanel,
  GroupPanel,
  FilterRow,
} from "devextreme-react/data-grid";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  Button,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { useSnackbar } from "notistack";
import EditAccountPopup from "./EditAccountPopup";

const ChartOfAccountsTable = ({
  accounts,
  taxCodes,
  headers,
  accountTypes,
  cashFlows,
  grouping,
  accountDataSets,
  refetchData,
}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [processedAccounts, setProcessedAccounts] = useState([]);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isClone, setIsClone] = useState(false);

  const accTypeOrder = [
    "Current Assets",
    "Non-Current Assets",
    "Current Liabilities",
    "Non-Current Liabilities",
    "Equity",
    "Income",
    "Cost of Goods Sold",
    "Expenses",
    "Other Income",
    "Other Expenses",
  ];

  // Function to get the order index of an account type
  const getAccTypeOrder = (accTypeName) => {
    return accTypeOrder.indexOf(accTypeName);
  };

  useEffect(() => {
    if (
      !accounts.length ||
      !headers.length ||
      !taxCodes.length ||
      !cashFlows.length
    )
      return;
    const processedData = accounts.map((account) => {
      const accountType = accountTypes.find(
        (at) => at.actID === account.accType_actID,
      );
      const header = headers.find((h) => h.achID === account.accHeader_achID);
      const taxCode = taxCodes.find(
        (tc) => tc.txcID === account.accDefTxc_txcID,
      );
      const cashFlow = cashFlows.find((cf) => cf.chfID === account.accCashFlow);

      return {
        ...account,
        accTypeName: accountType ? accountType.actName : "",
        accHeaderName: header ? header.achDrCrName : "",
        accTaxCode: taxCode ? taxCode.txcCode : "",
        accCashFlowName: cashFlow ? cashFlow.chfName : "",
        accTypeOrder: getAccTypeOrder(accountType ? accountType.actName : ""),
      };
    });

    // Sort the processed accounts by account type order
    processedData.sort((a, b) => a.accTypeOrder - b.accTypeOrder);

    setProcessedAccounts(processedData);
  }, [accounts, accountTypes, headers, taxCodes, cashFlows]);

  const handleActionClick = (event, account) => {
    event.stopPropagation();
    setAnchorPosition({
      top: event.nativeEvent.clientY,
      left: event.nativeEvent.clientX,
    });
    setSelectedAccount(account);
  };

  const handleMenuClose = () => {
    setAnchorPosition(null);
  };

  const handleClone = () => {
    setIsClone(true);
    setIsEditPopupOpen(true);
    handleMenuClose();
  };

  const handleEdit = () => {
    setIsClone(false);
    setIsEditPopupOpen(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleConfirmDelete = async () => {
    try {
      const deleteUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/delete?accountNo=${selectedAccount.accNo}&BaseHostURL=${envConfig.mainServiceUrl}`;

      await apiCall({
        url: deleteUrl,
        method: "POST",
        onSuccess: async () => {
          await refetchData();
          enqueueSnackbar("Account deleted successfully", {
            variant: "success",
          });
          setIsDeleteDialogOpen(false);
          setSelectedAccount(null);
        },
        onError: (errorMessage) => {
          enqueueSnackbar(`Failed to delete account: ${errorMessage}`, {
            variant: "error",
          });
          setIsDeleteDialogOpen(false);
        },
      });
    } catch (error) {
      enqueueSnackbar(`Failed to delete account: ${error.message}`, {
        variant: "error",
      });
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <DataGrid
        dataSource={processedAccounts}
        showBorders={true}
        wordWrapEnabled={true}
        keyExpr="accNo"
        grouping={{
          autoExpandAll: false,
        }}
      >
        <SearchPanel
          visible={true}
          width={240}
          placeholder="Search..."
          searchExpr={["accNo", "accName"]} // Restrict search to Account No and Account Name
        />
        <Grouping autoExpandAll={true} />
        <Scrolling mode="virtual" />
        <Paging defaultPageSize={10} />
        {grouping === "Group by type" && (
          <Column
            dataField="accTypeName"
            groupIndex={0}
            calculateGroupValue={(rowData) =>
              getAccTypeOrder(rowData.accTypeName)
            }
            calculateSortValue={(rowData) =>
              getAccTypeOrder(rowData.accTypeName)
            }
            groupCellRender={(cellData) => {
              const accTypeName = accTypeOrder[cellData.value];
              return <span>{accTypeName}</span>;
            }}
          />
        )}
        <Column dataField="accNo" caption="Account No" />
        <Column dataField="accName" caption="Account Name" />
        <Column dataField="accTaxCode" caption="Default Tax Code" />
        <Column
          dataField="accPostableYN"
          caption="Postable"
          allowFiltering={true} // Enable filtering
          calculateDisplayValue={(rowData) =>
            rowData.accPostableYN === "Y" ? "Yes" : "No"
          }
        />
        <Column
          dataField="accCashFlowName"
          caption="Cash Flow"
          allowFiltering={true} // Enable filtering
        />
        <Column
          dataField="accHeaderName"
          caption="Header"
          allowFiltering={true} // Enable filtering
        />
        <Column
          caption="Actions"
          cellRender={({ data }) => (
            <IconButton
              size="small"
              onClick={(event) => handleActionClick(event, data)}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        />
      </DataGrid>

      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={Boolean(anchorPosition)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleClone}>Clone</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      {isEditPopupOpen && (
        <EditAccountPopup
          refetchData={refetchData}
          accountDataSets={accountDataSets}
          setOpenAddAccountPopup={(open) => {
            setIsEditPopupOpen(open);
            if (!open) {
              setSelectedAccount(null);
            }
          }}
          taxCodes={taxCodes}
          accounts={accounts}
          initialData={
            isClone ? { ...selectedAccount, accNo: "" } : selectedAccount
          }
          isClone={isClone}
        />
      )}

      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Are you sure you want to delete this account?</DialogTitle>
        <Box p={2} display="flex" justifyContent="space-evenly">
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
          <Button onClick={handleCloseDeleteDialog} variant="outlined">
            Cancel
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ChartOfAccountsTable;
