import { useEffect, useState } from "react";
import { getCookie, getHeaders } from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { BankFeedsMainView } from "../../components/BankFeeds/BankFeedsMainView";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";

const BankFeedsLinked = ({}) => {
  const { dbId, lang, token } = useParams();
  const { apiCall } = useApi();
  const ip = useGetIP();
  const prefix = `/${dbId}/${lang}/`;
  const headers = getHeaders();

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [loadingActivateDeactivate, setLoadingActivateDeactivate] =
    useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState(null);
  const [loading1, setLoading1] = useState(true);

  const fetchConnectedAccounts = async () => {
    await apiCall({
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/get-connected-accounts?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "GET",
      ip: ip,
      headers,
      onSuccess: (data) => {
        setConnectedAccounts(data);
        setLoading1(false);
      },
      onError: (errorMessage) => {
        console.log(errorMessage);
        setLoading1(false);
      },
    });
  };

  useEffect(() => {
    fetchConnectedAccounts();
  }, [shouldRefetch, ip]);

  const { enqueueSnackbar } = useSnackbar();

  const handleActivate = async ({ accountId, connectionId }) => {
    setLoadingActivateDeactivate(true);
    await apiCall({
      ip: ip,
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/activate-account?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        accountId: accountId,
        connectionId: connectionId,
      },
      onSuccess: (data) => {
        setShouldRefetch(true);
        setLoadingActivateDeactivate(false);
      },
      onError: (errorMessage) => {
        setLoadingActivateDeactivate(false);
        enqueueSnackbar({
          variant: "error",
          message: errorMessage || "Error activating account",
        });
      },
    });
  };

  const handleDeactivate = async ({ accountId, connectionId }) => {
    setLoadingActivateDeactivate(true);
    await apiCall({
      ip: ip,
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/deactivate-account?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        accountId: accountId,
        connectionId: connectionId,
      },
      onSuccess: (data) => {
        setShouldRefetch(true);
        setLoadingActivateDeactivate(false);
      },
      onError: (errorMessage) => {
        setLoadingActivateDeactivate(false);
        enqueueSnackbar({
          variant: "error",
          message: errorMessage || "Error deactivating account",
        });
      },
    });
  };

  const onNewConnection = async () => {
    setLoadingActivateDeactivate(true);
    await apiCall({
      ip: ip,
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/create-new-bank-connection-link?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      onSuccess: (data) => {
        const action = "&action=connect";
        if (data?.ConsentUrl) {
          setLoadingActivateDeactivate(false);
          window.location =
            data.ConsentUrl +
            `&state=${window.location.origin}/${getCookie("databaseId")}/en-au/my-bank-feeds` +
            action;
        }
      },
      onError: () => {
        setLoadingActivateDeactivate(false);
        enqueueSnackbar({
          variant: "error",
          message: "Something went wrong. Please try again later",
        });
      },
    });
  };

  useEffect(() => {
    if (shouldRefetch) setShouldRefetch(false);
  }, [shouldRefetch]);

  useEffect(() => {
    let fetchCount = 0;

    const fetchData = async () => {
      try {
        if (token && token !== "null") {
          const response = await fetch(
            `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/process-job?jobId=${token}&BaseHostURL=${envConfig.mainServiceUrl}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "olivs-api-real-ip": "115.70.37.103",
                "olivs-api-computer-name": "BTM-WS2019",
                "session-id": getCookie("sessionId"),
                "browser-agent": window.navigator.userAgent,
                "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
                "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
              },
            },
          );
          const data = await response.json();
          if (
            data.SuccessYN === "Y" &&
            data.ConnectionId &&
            data.CredentialsValidatedYN === "Y"
          ) {
            enqueueSnackbar({
              variant: "success",
              message: "Basiq data has been updated",
            });
          } else {
            console.error("Data does not meet success criteria - refetching");
          }
        } else {
          console.log("no token");
          return;
        }
      } catch (error) {
        enqueueSnackbar({
          variant: "error",
          message: "Something went wrong. Please try again later",
        });
      }

      fetchCount += 1;
      if (fetchCount < 10) {
        setTimeout(fetchData, 7000);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <BackdropLoading open={loading1 || loadingActivateDeactivate} />
      <BankFeedsMainView
        prefix={prefix}
        handleDeactivate={handleDeactivate}
        handleActivate={handleActivate}
        onNewConnection={onNewConnection}
        data={connectedAccounts?.ListOfAccounts || []}
      />
    </div>
  );
};

export default BankFeedsLinked;
