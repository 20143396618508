import {
  Box,
  Card,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import PageTitle from "../../components/PageTitle/PageTitle";

import { ReactComponent as ContactsIcon } from "../../assets/contacts-menu-icon.svg";
import { ReactComponent as ReportsIcon } from "../../assets/reports-menu-icon.svg";
import { ReactComponent as CreateInvoiceIcon } from "../../assets/create-invoice.svg";
import { ReactComponent as TimesheetIcon } from "../../assets/timesheet.svg";
import { ReactComponent as BankFeedsIcon } from "../../assets/bank-feeds.svg";
import { ReactComponent as InvoicesListIcon } from "../../assets/invoices-list.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie, getLoginSecured } from "../../utils/api";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import numeral from "numeral";
import { useLoginPopup } from "../../context/LoginPopupContext";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { createSessionForExternal } from "../../utils/reusable";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";

const getClickable = (dbiD) => {
  return [
    {
      label: "Create Invoice",
      icon: <CreateInvoiceIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/invoice/standard`,
    },
    {
      label: "Timesheet",
      icon: <TimesheetIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/timesheet/list`,
    },
    {
      label: "Bank Feeds",
      icon: <BankFeedsIcon />,
      url: `/bank-feeds-linked`,
    },
    {
      label: "Invoices list",
      icon: <InvoicesListIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/invoice/list`,
    },
    {
      label: "Contacts list",
      icon: <ContactsIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/contacts/list`,
    },
    {
      label: "Reports",
      icon: <ReportsIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/reports/dashboard`,
    },
  ];
};

const DashboardMobile = () => {
  const { apiCall } = useApi();
  const databaseIdFromCookies = getCookie("databaseId");
  const { dbId, lang } = useParams();
  const urlPrefix = `/${dbId || databaseIdFromCookies}/${lang || "en-au"}`;

  const navigate = useNavigate();

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [data, setData] = useState([]);

  const [userFirstName, setUserFirstName] = useState("");
  const [clickable, setClickable] = useState([]);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen } = useLoginPopup();

  const fetchData = async () => {
    setLoading(true);
    let key = getCookie("apiKey");

    try {
      const response = await axios.post(
        `https://${envConfig.apiDev2}/api/en-au/front-end/get-mobile-dashboard-data?BaseHostURL=${envConfig.mainServiceUrl}`,
        {
          LinkA: `https://${envConfig.correspondingService}`,
          LinkS: "",
          Lang: "en-au",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key": key + "-" + getCookie("databaseId"),
          },
        },
      );

      const responseData = response.data;
      if (responseData.SuccessYN === "Y") {
        setLoading(false);
        setData(responseData.Data);
        setUserFirstName(responseData.UserFirstName);
      } else {
        setLoading(false);
        handleOpen();
        enqueueSnackbar(responseData.ErrorMessage || "Unknown error occurred", {
          variant: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      handleOpen();
      enqueueSnackbar("Error occurred while fetching data", {
        variant: "error",
      });
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!data?.length) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    setClickable(getClickable(getCookie("databaseId")));
  }, [getCookie("databaseId")]);

  return (
    <Box sx={{ mb: 8 }}>
      <BackdropLoading open={loading} />
      <Box sx={{ mb: 2 }}>
        <PageTitle title={userFirstName ? `Hi, ${userFirstName}!` : "Hi!"} />
        {data?.BusinessName && (
          <Typography sx={{ fontWeight: 600, color: "darkgray" }}>
            {data.BusinessName}
          </Typography>
        )}
      </Box>
      <Grid container alignItems="baseline" spacing={2}>
        {clickable.map(({ label, icon, url }, index) => (
          <Grid item xs={4} key={index}>
            <Card
              onClick={() => {
                if (url.startsWith("http")) {
                  createSessionForExternal(
                    url,
                    "115.70.37.103",
                    false,
                    apiCall,
                  );
                } else {
                  navigate(`${urlPrefix}${url}`);
                }
              }}
              sx={{
                textAlign: "center",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.1)",
                padding: "15px 5px",
                cursor: "pointer",
                height: "100%",
                color: "#495670",
                fill: isDarkMode ? "#fff" : "#818595",
                "&:hover": {
                  color: theme.palette.primary.main,
                  fill: theme.palette.primary.main,
                  path: {
                    fill: theme.navigation.hoverMenuItemColor,
                  },
                },
              }}
            >
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  "svg path": {
                    fill: isDarkMode ? "#fff" : "#818595",
                  },
                }}
              >
                {icon}
              </Box>
              <Box
                sx={{
                  margin: "0px",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  letterSpacing: "0.15px",
                }}
              >
                {label}
              </Box>
            </Card>
          </Grid>
        ))}

        {data?.length > 0 && (
          <Box
            sx={{
              width: "100%",
              borderRadius: 1,
              margin: "20px 0 20px 15px",
              backgroundColor: theme.palette.blackWhite,
              boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.1)",
              padding: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  mb: "20px",
                  color: "#495670",
                  fontSize: "24px",
                  fontWeight: 400,
                  letterSpacing: "-0.264px",
                }}
              >
                Business summary
              </Typography>
              {/* <Typography
                sx={{
                  padding: "15px 0px 20px 0px",
                  color: "#495670",
                  fontSize: "16px",
                  fontWeight: 300,
                  letterSpacing: "-0.176px",
                }}
              >
                Profit Loss YTD
              </Typography> */}
            </Box>
            <Box sx={{ width: "100%" }}>
              {data?.map(({ Label, Value, Url }, index) => (
                <Box
                  sx={{
                    cursor: "pointer",
                    py: "6px",
                    my: 0,
                    px: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: isDarkMode ? "#1E1E1E" : "#f4f4f4",
                    },
                  }}
                  key={index}
                  onClick={() => {
                    if (Url.startsWith("http")) {
                      createSessionForExternal(
                        Url,
                        "115.70.37.103",
                        false,
                        apiCall,
                      );
                    } else {
                      navigate(`${Url}`);
                    }
                  }}
                >
                  <Typography>{Label}</Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {Value >= 0
                      ? `$${numeral(Math.abs(Value)).format("0,0.00")}`
                      : `($${numeral(Math.abs(Value)).format("0,0.00")})`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default DashboardMobile;
